import { useState, useEffect } from 'react';
import { ProductService } from '../services/ProductService';
import { Product } from '../types/Product';

interface UseFilteredProductsProps {
  selectedCategory: string;
  selectedSubcategory: string;
  activeFilters: string[];
  searchTerm?: string; 
}

export const useFilteredProducts = ({
  selectedCategory,
  selectedSubcategory,
  activeFilters,
  searchTerm = "",
}: UseFilteredProductsProps) => {
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchAndFilterProducts = async () => {
      console.log("Fetching all products for client-side filtering.");
      setLoading(true);
      setError(null);
      try {
        const allProducts = await ProductService.fetchAllProducts();
        let filtered = allProducts;

        if (selectedCategory !== "all") {
          filtered = filtered.filter(product =>
            product.category.toLowerCase() === selectedCategory.toLowerCase()
          );
        }

        if (selectedSubcategory !== "all") {
          filtered = filtered.filter(product =>
            product.subcategory.toLowerCase() === selectedSubcategory.toLowerCase()
          );
        }

        if (activeFilters.length > 0) {
          filtered = filtered.filter(product =>
            activeFilters.includes(product.subcategory)
          );
        }

        // Filtrar por término de búsqueda
        if (searchTerm.trim() !== "") {
          filtered = filtered.filter(product =>
            product.name.toLowerCase().includes(searchTerm.toLowerCase())
          );
        }

        console.log("Filtered products:", filtered);
        setFilteredProducts(filtered);
      } catch (err) {
        console.error("Error fetching filtered products:", err);
        setError("Error al obtener los productos filtrados.");
      } finally {
        setLoading(false);
      }
    };

    fetchAndFilterProducts();
  }, [selectedCategory, selectedSubcategory, activeFilters, searchTerm]);

  return { filteredProducts, loading, error };
};