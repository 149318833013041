import EPP1 from '../assets/images/EPP1.jpg';
import Placeholder from '../assets/images/Placeholder.svg';
import Señalizacion from '../assets/images/Senalizacion.jpg';
// Importa los PDFs de cada categoría
import CatalogEPPs from '../assets/pdf/catalogo.pdf';
import eppsSubcategories from './subcategories/eppsSubcategories';
import senalizacionSubcategories from './subcategories/senalizacionSubcategories';
import ferreteriaSubcategories from './subcategories/ferreteriaSubcategories';
import { Shield, HardHat, AlertTriangle, Wrench } from 'lucide-react';

interface Subcategory {
  id: string;
  title: string;
  description: string;
  icon: React.ComponentType<any>;
  image: string;
}

interface Category {
  id: string;
  title: string;
  description: string;
  icon: React.ComponentType<any>;
  image: string;
  gradient: string;
  catalog: string;
  subcategories: Subcategory[] | null;
}

const categories: Category[] = [
  {
    id: "Equipos de Protección Personal (EPP)", // Coincide con Firestore
    title: "Equipos de Protección Personal (EPP)",
    description: "Equipamiento especializado para la seguridad laboral",
    icon: Shield,
    image: EPP1,
    gradient: "from-[#8BA888] to-blue-600",
    catalog: CatalogEPPs,
    subcategories: eppsSubcategories,
  },
  {
    id: "Señalización", // Coincide con Firestore
    title: "Señalización",
    description: "Señalización vial y minera de alta visibilidad",
    icon: AlertTriangle,
    image: Señalizacion,
    gradient: "from-blue-600 to-[#8BA888]",
    catalog: CatalogEPPs,
    subcategories: senalizacionSubcategories,
  },
  {
    id: "Ferretería", // Coincide con Firestore
    title: "Ferretería",
    description: "Herramientas y materiales de alta calidad",
    icon: Wrench,
    image: Placeholder,
    gradient: "from-[#8BA888] to-blue-600",
    catalog: CatalogEPPs,
    subcategories: ferreteriaSubcategories,
  },
  {
    id: "Servicios", // Asegúrate de que coincida con Firestore si existe
    title: "Servicios",
    description: "Descubre todos los servicios que hacemos",
    icon: HardHat,
    image: Placeholder,
    gradient: "from-blue-600 to-[#8BA888]",
    catalog: CatalogEPPs,
    subcategories: null,
  },
];

export default categories;