import React from 'react';
import { motion } from 'framer-motion';
import CountUp from 'react-countup';
import { Card, CardContent } from "../../../components/ui/Card";

interface Statistic {
  number: number;
  suffix?: string;
  label: string;
}

const statistics: Statistic[] = [
  { number: 100, suffix: '+', label: "Clientes Satisfechos" },
  { number: 10, suffix: '+', label: "Años de Experiencia" },
  { number: 24, suffix: '/7', label: "Soporte al Cliente" }
];

// Definición de variantes para la animación
const cardVariants = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: (custom: { delay: number }) => ({
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.6,
      ease: "easeOut",
      delay: custom.delay,
    },
  }),
};

const StatisticsSection: React.FC = () => {
  // Supongamos 3 columnas en pantallas medianas (md)
  const columns = 3;
  const baseDelay = 0.3; // Retardo base por fila

  return (
    <section className="py-16 bg-gradient-to-b from-white to-[#8BA888]/5">
      <div className="container mx-auto max-w-4xl px-4">
        <div className="grid md:grid-cols-3 gap-8 text-center">
          {statistics.map((stat, index) => {
            const rowNumber = Math.floor(index / columns);
            const delay = rowNumber * baseDelay; 

            return (
              <motion.div
                key={index}
                className="flex justify-center items-center p-4"
                variants={cardVariants}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.3 }}
                custom={{ delay }}
              >
                <Card className="border-none shadow-lg bg-gradient-to-br from-blue-50 to-white hover:shadow-xl transition-all duration-300">
                  <CardContent className="p-6">
                    <div className="text-4xl font-bold text-blue-900 mb-2">
                      <CountUp start={1} end={stat.number} duration={3} suffix={stat.suffix || ''} />
                    </div>
                    <p className="text-gray-600">{stat.label}</p>
                  </CardContent>
                </Card>
              </motion.div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default StatisticsSection;
