'use client'

import React, { useState } from 'react';
import categories from '../../../data/categories'; // Importar las categorías actualizadas
import { ChevronDown, ChevronUp } from 'lucide-react'; // Asegúrate de tener lucide-react instalado

interface HeaderSectionProps {
  selectedCategory: string;
  setSelectedCategory: (category: string) => void;
  isAllProducts?: boolean; // Modo AllProducts
  showViewAll?: boolean; // Modo Filtrado Específico
  searchTerm: string; // Añadido para búsqueda
  setSearchTerm: (term: string) => void; // Añadido para búsqueda
}

export default function HeaderSection({
  selectedCategory,
  setSelectedCategory,
  isAllProducts = false,
  showViewAll = false,
  searchTerm,
  setSearchTerm,
}: HeaderSectionProps) {

  // Definir categorías a excluir
  const excludedCategories = ['servicios'];

  // Filtrar categorías excluyendo "Servicios"
  const filteredCategories = categories.filter(category => !excludedCategories.includes(category.id.toLowerCase()));

  // Estado para controlar el menú móvil
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);

  // Handler para el cambio de búsqueda
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    // Aquí puedes agregar lógica adicional si es necesario
  };

  return (
    <section className="pt-6 sm:pb-8 pb-2 bg-gradient-to-r from-[#8BA888]/10 to-blue-50">
      <div className="container max-w-6xl mx-auto px-4">
        <div className="flex flex-col items-center text-center mb-4">
          <h1 className="text-xl md:text-4xl font-extrabold text-gray-900 mb-4">
            Nuestros Productos
          </h1>
        </div>
        <nav className="w-full">
          {/* Campo de búsqueda siempre arriba, centrado */}
          <div className="flex justify-center mb-4">
            <input
              type="text"
              placeholder="Buscar productos..."
              value={searchTerm}
              onChange={handleSearchChange}
              className="w-full max-w-md sm:text-sm text-xs p-2 border border-blue-3 00 rounded-[20px] focus:outline-none focus:ring-2 focus:ring-blue-600"
            />
          </div>

          {/* Categorías para Desktop */}
          <div className="hidden sm:flex justify-center mb-4">
            <ul className="flex space-x-4">
              <li
                onClick={() => {
                  setSelectedCategory("all");
                  setIsMobileMenuOpen(false);
                }}
                className={`cursor-pointer text-gray-500 ${
                  selectedCategory === "all"
                    ? "font-semibold text-gray-900 border-b-2 border-blue-600"
                    : ""
                }`}
              >
                Todas
              </li>
              {filteredCategories.map((category) => (
                <li
                  key={category.id}
                  onClick={() => {
                    setSelectedCategory(category.id);
                    setIsMobileMenuOpen(false);
                  }}
                  className={`cursor-pointer text-gray-500 ${
                    selectedCategory === category.id
                      ? "font-semibold text-gray-900 border-b-2 border-blue-600"
                      : ""
                  }`}
                >
                  {category.title}
                </li>
              ))}
            </ul>
          </div>

          {/* Categorías para Mobile */}
          <div className="sm:hidden flex flex-col items-center mb-4">
            {/* Toggle para menú de categorías */}
            <button
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              className="flex items-center text-gray-700 focus:outline-none mb-3"
              aria-label="Toggle Menu"
            >
              <span className="text-lg font-bold">Categorías</span>
              {isMobileMenuOpen ? <ChevronUp className="ml-2" /> : <ChevronDown className="ml-2" />}
            </button>

            {/* Lista de categorías en Mobile */}
            {isMobileMenuOpen && (
              <ul className="flex flex-col space-y-2 items-center">
                <li
                  onClick={() => {
                    setSelectedCategory("all");
                    setIsMobileMenuOpen(false);
                  }}
                  className={`cursor-pointer text-gray-500 ${
                    selectedCategory === "all"
                      ? "font-semibold text-gray-900 border-b-2 border-blue-600"
                      : ""
                  }`}
                >
                  Todas
                </li>
                {filteredCategories.map((category) => (
                  <li
                    key={category.id}
                    onClick={() => {
                      setSelectedCategory(category.id);
                      setIsMobileMenuOpen(false);
                    }}
                    className={`cursor-pointer text-gray-500 ${
                      selectedCategory === category.id
                        ? "font-semibold text-gray-900 border-b-2 border-blue-600"
                        : ""
                    }`}
                  >
                    {category.title}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </nav>
      </div>
    </section>
  );
}