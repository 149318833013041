import { Badge } from "../../../components/ui/Badge";
import { Card, CardContent } from "../../../components/ui/Card";
import { Link } from "react-router-dom";

interface Subcategory {
  id: string;
  title: string;
  description: string;
  icon: any;
  image: string;
}

interface SubcategoriesGridSectionProps {
  categoria: string;
  subcategories: Subcategory[] | null;
  handleImageError: (event: React.SyntheticEvent<HTMLImageElement, Event>) => void;
}

export default function SubcategoriesGridSection({
  categoria,
  subcategories,
  handleImageError,
}: SubcategoriesGridSectionProps) {
  return (
    <section className="py-16 bg-gradient-to-b from-white to-gray-50">
      <div className="container max-w-6xl mx-auto">
        <div className="grid mx-3 md:grid-cols-2 lg:grid-cols-2 gap-8">
          {subcategories && subcategories.map((subcategory) => (
            <Link 
              key={subcategory.id} 
              to={`/productos/${categoria}/${subcategory.id}`}
            >
              <Card className="group cursor-pointer border-none shadow-lg hover:shadow-xl transition-all duration-300 overflow-hidden">
                <CardContent className="p-0">
                  <div className="relative h-64">
                    <div className="absolute inset-0 bg-gradient-to-r from-[#8BA888] to-blue-600 opacity-90 group-hover:opacity-100 transition-opacity" />
                    <img
                      src={subcategory.image}
                      alt={subcategory.title}
                      className="object-cover h-full w-full"
                      onError={handleImageError}
                    />
                    <div className="absolute inset-0 p-6 flex flex-col justify-between">
                      <div className="flex items-start justify-between">
                        <Badge className="bg-white/90 text-[#8BA888]">
                          Ver Productos
                        </Badge>
                        <subcategory.icon className="w-6 h-6 text-white" />
                      </div>
                      <div>
                        <h2 className="text-xl font-bold text-white mb-2">
                          {subcategory.title}
                        </h2>
                        <p className="text-white/90 text-sm">
                          {subcategory.description}
                        </p>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
}