import React, { useState, useEffect } from 'react';
import InputField from '../components/InputField';
import Swal from 'sweetalert2';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { collection, setDoc, doc } from 'firebase/firestore';
import { storage, db } from '../../../firebase';
import { FaBarcode, FaTag, FaListAlt, FaClipboardList, FaTools, FaUpload, FaSave, FaTimes } from 'react-icons/fa';

const categoryOptions = {
  "Equipos de Protección Personal (EPP)": [
    "Cascos de Seguridad",
    "Orejeras de Seguridad",
    "Guantes de Seguridad",
    "Lentes de Seguridad",
    "Protección Minera",
    "Zapatos de Seguridad",
    "Protección Respiratoria",
    "Protección Corporal",
    "Bloqueo y Etiquetado",
  ],
  "Señalización": [
    "Señales Informativos",
    "Señales para Minería",
    "Señales para Obras",
    "Señales Preventivas",
    "Señales Reglamentarias",
    "Señales de Seguridad",
    "Señales en Banderines",
  ],
  "Ferretería": [
    "Materiales de Construcción",
    "Herramientas Eléctricas",
    "Electricidad e Iluminación",
    "Fijaciones y Sujeciones",
    "Herramientas Manuales",
    "Pinturas y Acabados",
    "Plomería y Accesorios",
  ],
};

const ProductsCrudSection = ({ onClose, fetchProducts, editingProduct }) => {
  const [formData, setFormData] = useState(
    editingProduct || {
      nombre: '',
      codigo: '',
      categoria: '',
      subcategoria: '',
      especificaciones: '',
      foto_url: null,
    }
  );
  const [uploading, setUploading] = useState(false);
  const [subcategories, setSubcategories] = useState([]);

  useEffect(() => {
    if (formData.categoria) {
      setSubcategories(categoryOptions[formData.categoria] || []);
    } else {
      setSubcategories([]);
    }
  }, [formData.categoria]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUploading(true);
    try {
      let imageUrl = formData.foto_url;
      if (imageUrl instanceof File) {
        const storageRef = ref(storage, `product_images/${formData.codigo}_${imageUrl.name}`);
        await uploadBytes(storageRef, imageUrl);
        imageUrl = await getDownloadURL(storageRef);
      }

      const productData = {
        codigo: formData.codigo,
        nombre: formData.nombre,
        categoria: formData.categoria,
        subcategoria: formData.subcategoria,
        especificaciones: formData.especificaciones,
        foto_url: imageUrl,
      };

      const productDocRef = doc(db, 'products', formData.codigo);
      await setDoc(productDocRef, productData);

      Swal.fire({
        title: '¡Éxito!',
        text: `Producto ${editingProduct ? 'actualizado' : 'agregado'} correctamente`,
        icon: 'success',
        confirmButtonText: 'Aceptar',
      });

      fetchProducts();
      onClose();
    } catch (err) {
      console.error('Error al guardar el producto:', err.message);
      Swal.fire({
        title: 'Error',
        text: `Hubo un problema al guardar el producto: ${err.message}`,
        icon: 'error',
        confirmButtonText: 'Aceptar',
      });
    } finally {
      setUploading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (!file.type.startsWith('image/')) {
        Swal.fire('Error', 'Por favor, selecciona un archivo de imagen válido.', 'error');
        return;
      }
      setFormData((prev) => ({ ...prev, foto_url: file }));
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center overflow-y-auto">
      <div className="bg-gray-900 text-white max-w-4xl w-full p-8 rounded-lg shadow-lg relative max-h-[90vh] overflow-y-auto">
        <button
          className="absolute top-4 right-4 text-gray-300 hover:text-gray-100"
          onClick={() => onClose(false)}
        >
          <FaTimes />
        </button>
        <h1 className="text-2xl font-bold mb-6">
          {editingProduct ? 'Editar Producto' : 'Agregar Producto'}
        </h1>
        <form onSubmit={handleSubmit} className="space-y-6">
          <InputField
            label={
              <>
                <FaBarcode className="inline-block mr-2" />
                Código
              </>
            }
            name="codigo"
            value={formData.codigo}
            onChange={handleChange}
            placeholder="Código único del producto"
            disabled={!!editingProduct}
          />
          <InputField
            label={
              <>
                <FaTag className="inline-block mr-2" />
                Nombre del producto
              </>
            }
            name="nombre"
            value={formData.nombre}
            onChange={handleChange}
            placeholder="Nombre del producto"
          />
          <div>
            <label className="block text-sm font-medium">
              <FaListAlt className="inline-block mr-2" />
              Categoría
            </label>
            <select
              name="categoria"
              value={formData.categoria}
              onChange={handleChange}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md text-black"
            >
              <option value="">Selecciona una categoría</option>
              {Object.keys(categoryOptions).map((category) => (
                <option key={category} value={category}>
                  {category}
                </option>
              ))}
            </select>
          </div>

          {subcategories.length > 0 && (
            <div>
              <label className="block text-sm font-medium">
                <FaClipboardList className="inline-block mr-2" />
                Subcategoría
              </label>
              <select
                name="subcategoria"
                value={formData.subcategoria}
                onChange={handleChange}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md text-black"
              >
                <option value="">Selecciona una subcategoría</option>
                {subcategories.map((subcategory) => (
                  <option key={subcategory} value={subcategory}>
                    {subcategory}
                  </option>
                ))}
              </select>
            </div>
          )}

          <InputField
            label={
              <>
                <FaTools className="inline-block mr-2" />
                Especificaciones
              </>
            }
            name="especificaciones"
            value={formData.especificaciones}
            onChange={handleChange}
            placeholder="Especificaciones del producto"
          />

          <div>
            <label className="block text-sm font-medium">
              <FaUpload className="inline-block mr-2" />
              Subir Foto
            </label>
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              className="mt-1 block w-full text-sm text-gray-500 file:py-2 file:px-4 file:rounded-lg file:border-0 file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
            />
          </div>

          {uploading && <p className="text-sm text-gray-300">Cargando imagen...</p>}
          <button
            type="submit"
            className="flex items-center justify-center gap-2 bg-blue-600 text-white font-bold py-2 px-4 rounded hover:bg-blue-700 w-full"
            disabled={uploading}
          >
            {uploading ? 'Cargando...' : <><FaSave /> Guardar Producto</>}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ProductsCrudSection;
