import FerreteriaLogo1 from '../assets/images/ferreteria/3M FERR.png';
import FerreteriaLogo2 from '../assets/images/ferreteria/ANYPSA FERRE.png';
import FerreteriaLogo3 from '../assets/images/ferreteria/BOCH FERRE.png';
import FerreteriaLogo4 from '../assets/images/ferreteria/INDECO FERRE.png';
import FerreteriaLogo5 from '../assets/images/ferreteria/KNAUF FERRE.png';
import FerreteriaLogo6 from '../assets/images/ferreteria/NORTON FERRE.png';
import FerreteriaLogo7 from '../assets/images/ferreteria/SATA FERRE.png';
import FerreteriaLogo8 from '../assets/images/ferreteria/STANLEY FERRE.png';
import FerreteriaLogo9 from '../assets/images/ferreteria/TRAMONTINA FERRE.png';
import FerreteriaLogo10 from '../assets/images/ferreteria/TRAVEX FERRE.png';
import FerreteriaLogo11 from '../assets/images/ferreteria/TRUPER FERRE.png';
import EppsLogo1 from '../assets/images/epps/CLUTE EPP.png';
import EppsLogo2 from '../assets/images/epps/LIBUS EPP.png';
import EppsLogo3 from '../assets/images/epps/MSA EPP.png';
import EppsLogo4 from '../assets/images/epps/SEGPRO EPP.png';
import EppsLogo5 from '../assets/images/epps/SEGUSA EPP.png';
import EppsLogo6 from '../assets/images/epps/SHOWA EPP.png';
import EppsLogo7 from '../assets/images/epps/STEEL EPP.png';
import EppsLogo8 from '../assets/images/epps/TYVEK EPP.png';

export interface Brand {
  name: string;
  logo: string;
  category: 'Ferretería' | 'Equipos de Protección Personal (EPP)' | 'otras'; // Asegúrate de que los valores sean en minúsculas y sin acentos}
}
export const brands: Brand[] = [
  {
    name: '3M',
    logo: FerreteriaLogo1,
    category: 'Ferretería',
  },
  {
    name: 'Anypsa',
    logo: FerreteriaLogo2,
    category: 'Ferretería',
  },
  {
    name: 'Boch',
    logo: FerreteriaLogo3,
    category: 'Ferretería',
  },
  {
    name: 'Indeco',
    logo: FerreteriaLogo4,
    category: 'Ferretería',
  },
  {
    name: 'Knauf',
    logo: FerreteriaLogo5,
    category: 'Ferretería',
  },
  {
    name: 'Norton',
    logo: FerreteriaLogo6,
    category: 'Ferretería',
  },
  {
    name: 'SATA',
    logo: FerreteriaLogo7,
    category: 'Ferretería',
  },
  {
    name: 'Stanley',
    logo: FerreteriaLogo8,
    category: 'Ferretería',
  },
  {
    name: 'Tramontina',
    logo: FerreteriaLogo9,
    category: 'Ferretería',
  },
  {
    name: 'Travex',
    logo: FerreteriaLogo10,
    category: 'Ferretería',
  },
  {
    name: 'Truper',
    logo: FerreteriaLogo11,
    category: 'Ferretería',
  },
  {
    name: 'Clute',
    logo: EppsLogo1,
    category: 'Equipos de Protección Personal (EPP)',
  },
  {
    name: 'Libus',
    logo: EppsLogo2,
    category: 'Equipos de Protección Personal (EPP)',
  },
  {
    name: 'MSA',
    logo: EppsLogo3,
    category: 'Equipos de Protección Personal (EPP)',
  },
  {
    name: 'Segpro',
    logo: EppsLogo4,
    category: 'Equipos de Protección Personal (EPP)',
  },
  {
    name: 'Segusa',
    logo: EppsLogo5,
    category: 'Equipos de Protección Personal (EPP)',
  },
  {
    name: 'Showa',
    logo: EppsLogo6,
    category: 'Equipos de Protección Personal (EPP)',
  },
  {
    name: 'Steel',
    logo: EppsLogo7,
    category: 'Equipos de Protección Personal (EPP)',
  },
  {
    name: 'Tyvek',
    logo: EppsLogo8,
    category: 'Equipos de Protección Personal (EPP)',
  },
  {
    name: '3M',
    logo: FerreteriaLogo1,
    category: 'Equipos de Protección Personal (EPP)',
  },
];
