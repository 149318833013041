import { useState, useEffect } from 'react';
import { ProductService } from '../services/ProductService';
import { Product } from '../types/Product';

interface UseProductProps {
  model: string | undefined;
}

export const useProduct = ({ model }: UseProductProps) => {
  const [product, setProduct] = useState<Product | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchProduct = async () => {
      if (!model) {
        setError("Código no especificado.");
        setLoading(false);
        return;
      }

      setLoading(true);
      setError(null);
      try {
        const fetchedProduct = await ProductService.fetchProductByModel(model);
        if (fetchedProduct) {
          setProduct(fetchedProduct);
        } else {
          setError("Producto no encontrado.");
        }
      } catch (err) {
        console.error("Error fetching product:", err);
        setError("Error al obtener el producto.");
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [model]);

  return { product, loading, error };
};