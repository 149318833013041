'use client'

import React from 'react';
import { Badge } from "../../../components/ui/Badge";
import { Card, CardContent } from "../../../components/ui/Card";
import { Link } from "react-router-dom";
import NotFound from '../../../assets/images/NotFound.png';
import { Product } from '../../../types/Product'; 

interface ProductGridSectionProps {
  products: Product[];
  loading: boolean;
  handleImageError: (event: React.SyntheticEvent<HTMLImageElement, Event>) => void;
}

const PlaceholderCard = () => (
  <div className="w-full max-w-xs sm:max-w-sm lg:max-w-[276px] mx-auto">
    <Card className="border-none shadow-lg animate-pulse flex flex-col h-full">
      <CardContent className="p-4 sm:p-6 flex flex-col flex-grow">
        <div className="relative w-full h-40 sm:h-48 bg-gray-200 rounded-lg overflow-hidden flex items-center justify-center">
          <div className="w-full h-full bg-gray-300"></div>
        </div>
        <div className="mt-4 text-center space-y-2">
          <div className="h-4 bg-gray-200 rounded w-3/4 mx-auto"></div>
          <div className="h-4 bg-gray-200 rounded w-1/2 mx-auto"></div>
          <div className="h-4 bg-gray-200 rounded w-1/3 mx-auto"></div>
        </div>
      </CardContent>
    </Card>
  </div>
);

export default function ProductGridSection({
  products,
  loading,
  handleImageError,
}: ProductGridSectionProps) {
  const placeholderArray = Array.from({ length: 20 }); // Mostrar 20 placeholders

  return (
    <section className="py-12">
      <div className="container max-w-7xl mx-auto px-4">
        <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          {loading
            ? placeholderArray.map((_, index) => (
                <PlaceholderCard key={index} />
              ))
            : products.length === 0 
              ? (
                <p className="text-center text-gray-500 col-span-full">No se encontraron productos.</p>
              )
              : (
                products.map((product) => (
                  <Link
                    key={product.model} // Usar 'model' como key si es único
                    to={`/productos/${encodeURIComponent(product.category.toLowerCase())}/${encodeURIComponent(product.subcategory.toLowerCase())}/${encodeURIComponent(product.model)}`}
                    className="w-full max-w-xs sm:max-w-sm lg:max-w-[276px] mx-auto"
                  >
                    <Card className="border-none shadow-lg hover:shadow-blue-100 transition-all duration-300 flex flex-col h-full">
                      <CardContent className="p-4 sm:p-6 flex flex-col flex-grow">
                        <div className="relative w-full h-40 sm:h-48 bg-gray-50 rounded-lg overflow-hidden flex items-center justify-center">
                          <img
                            src={product.image || NotFound}
                            alt={product.name}
                            className="object-contain w-full h-full"
                            onError={handleImageError}
                          />
                          <Badge className="absolute top-2 right-2 bg-[#8BA888] text-[8px] sm:text-xs px-1 sm:px-2 py-0.5 rounded-xl">
                            {product.subcategory.charAt(0).toUpperCase() + product.subcategory.slice(1)}
                          </Badge>
                        </div>
                        <div className="mt-4 text-center">
                          <h2 className="sm:text-lg text-base font-semibold">{product.name}</h2>
                          <p className="text-gray-600 sm:text-base text-sm">Modelo: {product.model}</p>
                          <p className="text-indigo-900 italic sm:text-base text-sm">
                            {product.category.charAt(0).toUpperCase() + product.category.slice(1)}
                          </p>
                        </div>
                      </CardContent>
                    </Card>
                  </Link>
                ))
              )
          }
        </div>
      </div>
    </section>
  );
}