import React from 'react';
import { Badge } from "../../../components/ui/Badge";
import { Hexagon } from 'lucide-react';
import { FaStar, FaHandsHelping, FaBalanceScale, FaHandshake, FaLightbulb, FaClock } from 'react-icons/fa';

interface Value {
  title: string;
  description: string;
  icon: React.ReactNode;
}

const values: Value[] = [
  { 
    title: "Excelencia", 
    description: "Buscar siempre lo mejor continuamente.", 
    icon: <FaStar size={24} color="#1E40AF" /> 
  },
  { 
    title: "Responsabilidad", 
    description: "Actuar con consciencia y diligencia.", 
    icon: <FaHandsHelping size={24} color="#1E40AF" /> 
  },
  { 
    title: "Integridad", 
    description: "Principios éticos sólidos en acción.", 
    icon: <FaBalanceScale size={24} color="#1E40AF" /> 
  },
  { 
    title: "Compromiso", 
    description: "Perseverar y superar cualquier desafío.", 
    icon: <FaHandshake size={24} color="#1E40AF" /> 
  },
  { 
    title: "Innovación", 
    description: "Creatividad y adaptación a cambios.", 
    icon: <FaLightbulb size={24} color="#1E40AF" /> 
  },
  { 
    title: "Puntualidad", 
    description: "Cumplir con horarios y plazos.", 
    icon: <FaClock size={24} color="#1E40AF" /> 
  },
];

const ValuesSection: React.FC = () => {
  return (
    <section className="py-16 bg-gradient-to-b from-white to-[#8BA888]/5">
      <div className="container mx-auto max-w-6xl">
        <div className="text-center mb-12">
          <Badge className="mb-4 bg-gradient-to-r from-[#8BA888] to-blue-600 text-white">
            Nuestros Principios
          </Badge>
          <h2 className="text-3xl font-bold text-gray-900">
            Valores que nos Definen
          </h2>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-8">
          {values.map((value, index) => (
            <div key={index} className="flex flex-col items-center text-center p-6">
              <div className="relative mb-4">
                <Hexagon className="w-16 h-16 text-[#8BA888] fill-blue-100/35" />
                <div className="absolute inset-0 flex items-center justify-center">
                  {value.icon}
                </div>
              </div>
              <h3 className="text-xl font-semibold text-gray-900 mb-2">{value.title}</h3>
              <p className="text-gray-600 text-sm">{value.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ValuesSection;