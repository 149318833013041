'use client'

import React from 'react'
import { motion } from 'framer-motion'
import { Plus } from 'lucide-react'

interface Service {
  title: string
  description: string
  image: string
}

interface ServiceCardProps {
  service: Service
  index: number
  onMoreInfo: () => void
  className?: string
}

const ServiceCard: React.FC<ServiceCardProps> = ({ service, index, onMoreInfo, className }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: index * 0.1 }}
      className={`bg-white rounded-[30px] overflow-hidden shadow-lg relative max-w-4xl ${className}`}
    >
      <div className="relative h-80">
        <img
          src={service.image || "/placeholder.svg"}
          alt={service.title}
          className="object-cover w-full h-full"
          onError={(e) => { (e.target as HTMLImageElement).src = '/placeholder.svg' }}
        />
        <div className="absolute inset-0 bg-black bg-opacity-40" />
        <div className="absolute bottom-0 left-0 p-6">
          <h2 className="sm:text-3xl text-2xl text-left font-bold max-w-[500px] text-white mb-2">{service.title}</h2>
        </div>
      </div>
      {/* Botón "+" */}
      <button
        onClick={onMoreInfo}
        className="absolute bottom-4 right-4 bg-blue-600 text-white rounded-full p-2 hover:bg-white hover:text-blue-600 transition-colors flex items-center justify-center"
        aria-label="Más información"
      >
        <Plus size={24} />
      </button>
    </motion.div>
  )
}

export default ServiceCard