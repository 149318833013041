// src/data/subcategories/senalizacionSubcategories.ts
import { Shield, Eye, HardHat } from 'lucide-react';
import Placeholder from '../../assets/images/Placeholder.svg';

const senalizacionSubcategories = [
  {
    id: "Señales Informativos",
    title: "Informativos",
    description: "Señalización vial de alta calidad",
    icon: Shield,
    image: Placeholder,
  },
  {
    id: "Señales para Minería",
    title: "Señales para Minería",
    description: "Señalización minera de alta calidad",
    icon: Eye,
    image: Placeholder,
  },
  {
    id: "Señales para Obras",
    title: "Señales para Obras",
    description: "Señalización industrial de alta calidad",
    icon: HardHat,
    image: Placeholder,
  },
  {
    id: "Señales Preventivas",
    title: "Señales Preventivas",
    description: "Señalización preventiva de alta calidad",
    icon: Eye,
    image: Placeholder,
  },
  {
    id: "Señales Reglamentarias",
    title: "Señales Reglamentarias",
    description: "Señalización reglamentaria de alta calidad",
    icon: Shield,
    image: Placeholder,
  },
  {
    id: "Señales de Seguridad",
    title: "Señales de Seguridad",
    description: "Señalización de seguridad de alta calidad",
    icon: HardHat,
    image: Placeholder,
  },
  {
    id: "Señales en Banderines",
    title: "Señales en Banderines",
    description: "Señalización en banderines de alta calidad",
    icon: Shield,
    image: Placeholder,
  }
];

export default senalizacionSubcategories;