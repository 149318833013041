import React, { createContext, useState, ReactNode } from 'react';
import { Product } from '../types/Product';

interface ProductsContextProps {
  allProducts: Product[];
  setAllProducts: React.Dispatch<React.SetStateAction<Product[]>>;
  filteredProducts: Product[];
  setFilteredProducts: React.Dispatch<React.SetStateAction<Product[]>>;
}

export const ProductsContext = createContext<ProductsContextProps | undefined>(undefined);

export const ProductsProvider = ({ children }: { children: ReactNode }) => {
  const [allProducts, setAllProducts] = useState<Product[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);

  return (
    <ProductsContext.Provider value={{ allProducts, setAllProducts, filteredProducts, setFilteredProducts }}>
      {children}
    </ProductsContext.Provider>
  );
};