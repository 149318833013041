import React from 'react';

interface HeroSectionProps {
  title: string;
}

const HeroSection: React.FC<HeroSectionProps> = ({ title }) => {
  return (
    <section className="relative h-[150px] bg-gradient-to-r from-[#8BA888] via-blue-600 to-blue-800">
      <div className="absolute inset-0 bg-white/10" />
      <div className="container mx-auto relative z-10 h-full flex items-center justify-center">
        <h1 className="text-4xl md:text-5xl font-bold text-white text-center">
          {title}
        </h1>
      </div>
    </section>
  );
};

export default HeroSection;