import { Badge } from "../../../components/ui/Badge";

export default function HeroSection() {
  return (
    <section className="relative h-[200px] bg-gradient-to-r from-[#8BA888] via-blue-600 to-blue-800">
      <div className="absolute inset-0 bg-white/10" />
      <div className="container relative z-10 h-full flex flex-col items-center justify-center text-center mx-auto">
        <Badge className="mb-4 bg-white/90 text-[#8BA888] hover:bg-white">
          Nuestros Productos
        </Badge>
        <h1 className="text-3xl md:text-5xl font-bold text-white mb-4">
          Líneas de Productos
        </h1>
        <p className="text-white/90 max-w-xl">
          Descubre nuestra amplia gama de productos especializados para cada necesidad
        </p>
      </div>
    </section>
  );
}