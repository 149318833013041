import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { onAuthStateChanged, User } from 'firebase/auth';
import { auth } from '../../../firebase'; // Asegúrate de que la ruta es correcta

const ProtectedRoute: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    // Suscribirse a los cambios de autenticación
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });

    // Limpieza al desmontar el componente
    return () => unsubscribe();
  }, []);

  if (loading) {
    // Puedes personalizar el indicador de carga
    return <div className="text-center mt-10">Cargando...</div>;
  }

  if (!user) {
    // Redirigir a la página de login si no está autenticado
    return <Navigate to="/admin/login" replace />;
  }

  // Renderizar las rutas protegidas
  return <Outlet />;
};

export default ProtectedRoute;