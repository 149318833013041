import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../assets/images/COHUIN TRANS.png";

const routes = [
  { name: "Inicio", href: "/" },
  { name: "Nosotros", href: "/nosotros" },
  {
    name: "Productos",
    href: "/productos",
    subItems: [
      { name: "Equipo de Protección Personal", href: "/productos/Equipos%20de%20Protección%20Personal%20(EPP)" },
      { name: "Señalización", href: "/productos/Señalización" },
      { name: "Ferretería", href: "/productos/Ferretería" },
      { name: "Servicios", href: "/productos/Servicios" },
      { name: "Ver todos", href: "/productos/todos" },
    ],
  },
  { name: "Clientes", href: "/nuestros-clientes" },
  { name: "Contacto", href: "/contacto" },
];

const Header: React.FC = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState<string | null>(null);
  const location = useLocation();
  const dropdownTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const toggleMobileDropdown = (routeName: string) => {
    setActiveDropdown((prev) => (prev === routeName ? null : routeName));
  };

  const handleMouseEnter = () => {
    if (dropdownTimeoutRef.current) {
      clearTimeout(dropdownTimeoutRef.current);
    }
    setDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    dropdownTimeoutRef.current = setTimeout(() => {
      setDropdownOpen(false);
    }, 200); // Ajusta el tiempo (en milisegundos) según tus necesidades
  };

  const handleMobileLinkClick = () => {
    setMobileMenuOpen(false);
    setActiveDropdown(null);
  };

  return (
    <>
      <header
        className={`fixed top-0 left-0 w-full z-[1050] transition-all duration-300 ${
          isScrolled ? "bg-white/20 shadow-lg backdrop-blur-lg" : "bg-white/90"
        }`}
        style={{ minHeight: "80px", lineHeight: "1" }}
      >
        <div className="max-w-7xl mx-auto flex justify-between items-center h-full px-4 sm:px-6 lg:px-8">
          <Link to="/" className="flex items-center">
            <img
              src={Logo}
              alt="Corporación Cohuin Logo"
              className="h-20 w-auto sm:h-24 transition-transform duration-500 hover:scale-105"
            />
          </Link>

          {/* Desktop Menu */}
          <nav className="hidden sm:flex items-center space-x-6">
            {routes.map((route) =>
              route.subItems ? (
                <div
                  key={route.name}
                  className="relative group"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <Link
                    to={route.href}
                    className={`text-xl font-semibold tracking-wide transition ${
                      isScrolled ? "text-[#33769F]" : "text-[#33769F]"
                    } hover:opacity-90`}
                  >
                    {route.name}
                  </Link>
                  {dropdownOpen && (
                    <div
                      className="absolute top-full left-0 mt-2 w-60 bg-white shadow-lg rounded-lg transition-all duration-300"
                    >
                      {route.subItems.map((subItem, index) => (
                        <React.Fragment key={subItem.name}>
                          <Link
                            to={subItem.href}
                            className={`block px-4 py-2 text-gray-800 font-medium hover:bg-[#33769F] hover:text-white rounded ${
                              location.pathname === subItem.href ? "bg-[#33769F] text-white" : ""
                            }`}
                            onClick={() => setDropdownOpen(false)}
                          >
                            {subItem.name}
                          </Link>
                          {index < route.subItems.length - 1 && <hr className="border-gray-300" />}
                        </React.Fragment>
                      ))}
                    </div>
                  )}
                </div>
              ) : (
                <Link
                  key={route.name}
                  to={route.href}
                  className={`text-xl font-semibold tracking-wide transition ${
                    isScrolled ? "text-[#33769F]" : "text-[#33769F]"
                  } hover:opacity-90`}
                >
                  {route.name}
                </Link>
              )
            )}
          </nav>

          {/* Mobile Menu Toggle */}
          <button
            className="sm:hidden p-2 border-2 rounded text-black transition-all duration-300 bg-white border-black"
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
          >
            ☰
          </button>

          {/* Mobile Menu */}
          {mobileMenuOpen && (
            <div className="absolute top-full left-0 w-full bg-white backdrop-blur-lg shadow-lg p-4 space-y-4 rounded-lg">
              {routes.map((route) => (
                <div key={route.name}>
                  {route.subItems ? (
                    <>
                      <button
                        className="block font-semibold text-gray-700 w-full text-left"
                        onClick={() => toggleMobileDropdown(route.name)}
                      >
                        {route.name}
                      </button>
                      {activeDropdown === route.name && (
                        <div className="ml-4 space-y-2">
                          {route.subItems.map((subItem) => (
                            <Link
                              key={subItem.name}
                              to={subItem.href}
                              className={`block px-4 py-2 font-medium text-gray-800 hover:bg-[#33769F] hover:text-white rounded ${
                                location.pathname === subItem.href ? "bg-[#33769F] text-white" : ""
                              }`}
                              onClick={handleMobileLinkClick}
                            >
                              {subItem.name}
                            </Link>
                          ))}
                        </div>
                      )}
                    </>
                  ) : (
                    <Link
                      to={route.href}
                      className="block font-semibold text-gray-700 px-4 py-2 hover:bg-[#33769F] hover:text-white rounded"
                      onClick={handleMobileLinkClick}
                    >
                      {route.name}
                    </Link>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </header>

      <div className="pt-[40px]"></div>
    </>
  );
};

export default Header;
