import React from 'react';
import { Card, CardContent } from "../../../components/ui/Card";
import { Badge } from "../../../components/ui/Badge";

const MainContentSection: React.FC = () => {
  return (
    <section className="py-16">
      <div className="container mx-auto max-w-6xl">
        <Card className="border-none shadow-xl overflow-hidden bg-gradient-to-br from-blue-50 to-white">
          <div className="grid md:grid-cols-2 items-center">
            <div className="p-8 md:p-12 order-2 md:order-1">
              <Badge className="mb-6 bg-blue-100 text-blue-900 hover:bg-blue-200">
                Compromiso con la Excelencia
              </Badge>
              <h2 className="text-2xl md:text-3xl font-bold text-blue-900 mb-4">
                Soluciones Integrales para Cada Sector
              </h2>
              <p className="text-gray-600 leading-relaxed mb-6">
                CORPORACIÓN COHUIN SAC atiende a clientes nacionales en el Perú, 
                abarcando diversos sectores. Estamos comprometidos a proporcionar 
                soluciones de alta calidad que garantizan seguridad y eficacia 
                para cada industria que atendemos.
              </p>
              <div className="flex flex-wrap gap-4">
                {['Minería', 'Industria', 'Construcción'].map((sector) => (
                  <Badge key={sector} variant="outline" className="text-blue-700">
                    {sector}
                  </Badge>
                ))}
              </div>
            </div>
            <div className="relative h-[400px] order-1 md:order-2">
              <div className="absolute inset-0 bg-gradient-to-r from-blue-900/10 to-transparent z-10" />
              <img
                src="/placeholder.svg?height=800&width=600"
                alt="Equipo corporativo en reunión"
                className="object-cover w-full h-full"
              />
            </div>
          </div>
        </Card>
      </div>
    </section>
  );
};

export default MainContentSection;