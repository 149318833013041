import React from "react";
import { motion } from "framer-motion";
import ContactForm from "../components/ui/ContactForm";
import ContactCard from "../components/ui/ContactCard";
import SEO from "../components/SEO";
import seoConfig from "../config/seoConfig";

const ContactPage: React.FC = () => {
  return (
    <div className="bg-gray-100 min-h-screen flex flex-col justify-between">
      <SEO
        title= 'Contactanos'
        description='Contacta con nosotros'
        url={`https://cohuin.com/contacto`}
        image={seoConfig.image.url}
        imageAlt={'Imagen de contacto de Corporación Cohuin SAC'}
      />
      {/* Header with Gradient */}
      <motion.div
        className="relative h-[250px] bg-gradient-to-r from-[#89A78A] to-[#2E66E6]"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        <div className="absolute inset-0 bg-white/10" />
        <div className="container relative z-10 h-full flex flex-col items-center justify-center text-center mx-auto px-6 sm:px-8">
          <motion.h1
            className="text-3xl sm:text-4xl md:text-5xl font-bold text-white mb-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 1 }}
          >
            Contacto
          </motion.h1>
          <motion.p
            className="text-sm sm:text-base lg:text-lg text-white/90 max-w-xs sm:max-w-md lg:max-w-2xl"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1, duration: 1 }}
          >
            Estamos aquí para ayudarte. Contáctanos hoy mismo y responde tus
            consultas con nuestra ayuda.
          </motion.p>
        </div>
      </motion.div>

      {/* Contact Cards */}
      <div className="container mx-auto px-4 py-8 sm:py-10 bg-white shadow-lg rounded-[30px]">
        <h2 className="text-lg sm:text-2xl font-bold text-center mb-6 text-gray-800">
          Contáctanos
        </h2>
        <p className="text-sm sm:text-base text-center mb-8 text-gray-600">
          Visítanos en nuestras oficinas o comunícate con nosotros mediante los
          siguientes medios:
        </p>

        <div className="grid gap-8 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
          <ContactCard
            title="COHUIN - GRAU - LIMA"
            address="Av. Miguel Grau 247, Int. 11, Cercado de Lima, Lima 15001"
            phone="+51 944 888 761"
            email="lima.ventas@cohuin.com"
            days="LUNES - SÁBADO"
            mapSrc="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d354411.02196940564!2d-77.032491!3d-12.059153!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c9ca78c5e077%3A0x5cc7a90cb001d29e!2sCORPORACION%20COHUIN%20SAC!5e1!3m2!1ses-419!2spe!4v1737258798551!5m2!1ses-419!2spe"
          />
          <ContactCard
            title="COHUIN - OYON - LIMA"
            address="Jr. Lima 198, Oyón 15274"
            phone="+51 974 657 332"
            email="oyon.ventas@cohuin.com"
            days="LUNES - SÁBADO"
            mapSrc="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d526.0904296444506!2d-76.7723930706695!3d-10.668632853237812!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9107eb453bb77297%3A0x33d57a6b01ff3f7a!2sCORPORACION%20COHUIN%20S.A.C!5e1!3m2!1ses-419!2spe!4v1737258849218!5m2!1ses-419!2spe"
          />
          <ContactCard
            title="OFICINA - ADMINISTRACIÓN"
            address="LIMA - LIMA"
            phone="+51 932 723 864"
            email="lsamar@cohuin.com"
            days="LUNES - SÁBADO"
            mapSrc="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d354455.06194605544!2d-77.31747100262818!3d-12.025773209987065!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c5f619ee3ec7%3A0x14206cb9cc452e4a!2sLima!5e1!3m2!1ses-419!2spe!4v1737258869240!5m2!1ses-419!2spe"
          />
        </div>
      </div>

      {/* Contact Form */}
      <motion.div
        className="container mx-auto mt-8 px-6 py-8 sm:py-10 bg-blue-50 shadow-lg rounded-[30px]"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.6, duration: 0.8 }}
      >
        <motion.h2
          className="text-lg sm:text-xl font-bold text-center mb-6 text-blue-700"
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ delay: 0.9, duration: 0.8 }}
        >
          Envíanos un mensaje
        </motion.h2>
        <ContactForm />
      </motion.div>
    </div>
  );
};

export default ContactPage;
