import HeroSection from './sections/ProductoSections/HeroSection';
import CategoriesGrid from './sections/ProductoSections/CategoriesGrid';
import BrandCarousel from '../components/BrandCarousel';
import { brands } from '../data/brands'; 
import SEO from '../components/SEO';
import seoConfig from '../config/seoConfig';
export default function ProductsPage() {
  return (
    <div className="min-h-screen bg-gray-50">
      <SEO
          title="Inicio"
          description="Explora nuestra amplia gama de productos y servicios en Corporación Cohuin SAC."
          url="https://cohuin.com/productos"
          image={seoConfig.image.url}
          imageAlt="Productos de Corporación Cohuin SAC"
      />
      {/* Hero Section */}
      <HeroSection />
      {/* Categories Grid */}
      <CategoriesGrid />

      <div className="container mx-auto px-4 py-16 bg-white rounded-t-[30px]">        <h1 className="text-3xl font-bold text-center text-gray-700 mb-12">Nuestras Marcas Asociadas</h1>
        <BrandCarousel brands={brands} className="mb-16" />
        <p className="text-center text-gray-600 max-w-2xl mx-auto">
          Trabajamos con las mejores marcas del mercado para ofrecerte productos de alta calidad y rendimiento.
        </p>
      </div>
    </div>
  );
}