import React, { useState } from "react";
import { motion } from "framer-motion";
import Swal from "sweetalert2";

const ContactForm: React.FC = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const [errors, setErrors] = useState({
    name: false,
    email: false,
    phone: false,
    subject: false,
    message: false,
  });

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { id, value } = e.target;

    if (id === "name" && /\d/.test(value)) {
      setErrors((prevErrors) => ({ ...prevErrors, name: true }));
    } else if (id === "phone" && /[^\d]/.test(value)) {
      setErrors((prevErrors) => ({ ...prevErrors, phone: true }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, [id]: false }));
    }

    setFormData((prevData) => ({ ...prevData, [id]: value }));
  };

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const { name, email, phone, subject, message } = formData;

    const newErrors = {
      name: !name || /\d/.test(name),
      email: !email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email),
      phone: !phone || /[^\d]/.test(phone),
      subject: !subject,
      message: !message,
    };

    setErrors(newErrors);

    if (Object.values(newErrors).some((error) => error)) {
      Swal.fire({
        icon: "error",
        title: "Campos inválidos",
        text: "Por favor, corrige los campos marcados antes de enviar.",
        confirmButtonText: "Entendido",
        confirmButtonColor: "#d33",
      });
      return;
    }

    try {
      const response = await fetch("/api/send-email", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ name, email, phone, subject, message }),
      });
      
      if (!response.ok) {
        // Obtén el texto completo del error para depuración.
        const errorText = await response.text();
        throw new Error(errorText);
      }
      
      const data = await response.json();
      
      if (response.ok) {
        Swal.fire({
          icon: "success",
          title: "Mensaje enviado",
          text: "Gracias por contactarnos. Nos comunicaremos contigo pronto.",
          confirmButtonText: "Aceptar",
          confirmButtonColor: "#3085d6",
        });

        // Reinicia el formulario y errores
        setFormData({
          name: "",
          email: "",
          phone: "",
          subject: "",
          message: "",
        });
        setErrors({
          name: false,
          email: false,
          phone: false,
          subject: false,
          message: false,
        });
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      console.error("Error al enviar el email:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Ocurrió un error al enviar el mensaje. Inténtalo de nuevo.",
        confirmButtonText: "Aceptar",
        confirmButtonColor: "#d33",
      });
    }
  };

  return (
    <motion.form
      onSubmit={handleFormSubmit}
      className="max-w-2xl mx-auto bg-white p-8 rounded-lg shadow-md"
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ delay: 0.3, duration: 0.8 }}
    >
      <div className="mb-4">
        <label htmlFor="name" className="block text-gray-700 font-medium mb-2">
          Nombre
        </label>
        <input
          type="text"
          id="name"
          value={formData.name}
          onChange={handleInputChange}
          className={`w-full border rounded-md px-4 py-2 focus:ring-2 ${
            errors.name
              ? "border-red-500 focus:ring-red-600"
              : "focus:ring-blue-600"
          } focus:outline-none text-black font-serif`}
          placeholder="Tu nombre completo"
        />
        {errors.name && (
          <p className="text-red-500 text-sm mt-1">
            El nombre no puede contener números.
          </p>
        )}
      </div>
      <div className="mb-4">
        <label htmlFor="email" className="block text-gray-700 font-medium mb-2">
          Correo electrónico
        </label>
        <input
          type="email"
          id="email"
          value={formData.email}
          onChange={handleInputChange}
          className={`w-full border rounded-md px-4 py-2 focus:ring-2 ${
            errors.email
              ? "border-red-500 focus:ring-red-600"
              : "focus:ring-blue-600"
          } focus:outline-none text-black font-serif`}
          placeholder="Tu correo electrónico"
        />
        {errors.email && (
          <p className="text-red-500 text-sm mt-1">
            Ingresa un correo válido.
          </p>
        )}
      </div>
      <div className="mb-4">
        <label htmlFor="phone" className="block text-gray-700 font-medium mb-2">
          Número de teléfono
        </label>
        <input
          type="tel"
          id="phone"
          value={formData.phone}
          onChange={handleInputChange}
          className={`w-full border rounded-md px-4 py-2 focus:ring-2 ${
            errors.phone
              ? "border-red-500 focus:ring-red-600"
              : "focus:ring-blue-600"
          } focus:outline-none text-black font-serif`}
          placeholder="Tu número de teléfono"
        />
        {errors.phone && (
          <p className="text-red-500 text-sm mt-1">
            Solo se permiten números en el teléfono.
          </p>
        )}
      </div>
      <div className="mb-4">
        <label htmlFor="subject" className="block text-gray-700 font-medium mb-2">
          Asunto
        </label>
        <input
          type="text"
          id="subject"
          value={formData.subject}
          onChange={handleInputChange}
          className={`w-full border rounded-md px-4 py-2 focus:ring-2 ${
            errors.subject
              ? "border-red-500 focus:ring-red-600"
              : "focus:ring-blue-600"
          } focus:outline-none text-black font-serif`}
          placeholder="Asunto del mensaje"
        />
        {errors.subject && (
          <p className="text-red-500 text-sm mt-1">
            El asunto no puede estar vacío.
          </p>
        )}
      </div>
      <div className="mb-6">
        <label htmlFor="message" className="block text-gray-700 font-medium mb-2">
          Mensaje
        </label>
        <textarea
          id="message"
          rows={5}
          value={formData.message}
          onChange={handleInputChange}
          className={`w-full border rounded-md px-4 py-2 focus:ring-2 ${
            errors.message
              ? "border-red-500 focus:ring-red-600"
              : "focus:ring-blue-600"
          } focus:outline-none text-black font-serif`}
          placeholder="Escribe tu mensaje aquí"
        ></textarea>
        {errors.message && (
          <p className="text-red-500 text-sm mt-1">
            El mensaje no puede estar vacío.
          </p>
        )}
      </div>
      <button
        type="submit"
        className="w-full bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-md transition-colors"
      >
        Enviar mensaje
      </button>
    </motion.form>
  );
};

export default ContactForm;
