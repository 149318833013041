"use client";
import { useEffect } from "react";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import { Star, ChevronLeft, ChevronRight, User } from "lucide-react";

const testimonials = [
  {
    name: "María Rodríguez",
    company: "Minera del Sur S.A.",
    content:
      "El equipamiento proporcionado por Corporación Cohuin ha mejorado significativamente la seguridad de nuestros trabajadores. Su servicio al cliente es excepcional.",
  },
  {
    name: "Carlos Mendoza",
    company: "Constructora Andes",
    content:
      "La calidad de los productos y la eficiencia en la entrega nos han convertido en clientes leales. Recomiendo ampliamente sus servicios.",
  },
  {
    name: "Ana Gómez",
    company: "Industrias Metálicas del Perú",
    content:
      "Su experiencia en estructuras metálicas es inigualable. Han superado nuestras expectativas en cada proyecto.",
  },
  {
    name: "Luis Paredes",
    company: "Seguridad Industrial S.A.C.",
    content:
      "Los EPP que nos suministran son de la más alta calidad. Nuestros empleados se sienten seguros y cómodos con ellos.",
  },
  {
    name: "Sofía Torres",
    company: "Minera Andina",
    content:
      "Su servicio de mantenimiento ha sido crucial para mantener nuestras operaciones en marcha. Son rápidos, eficientes y muy profesionales.",
  },
];

export default function TestimonialCarousel() {
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    loop: true,
    slides: {
      perView: 3, // 3 tarjetas por vista en pantallas grandes
      spacing: 16,
    },
    breakpoints: {
      "(max-width: 1024px)": {
        slides: {
          perView: 2,
          spacing: 16,
        },
      },
      "(max-width: 768px)": {
        slides: {
          perView: 1,
          spacing: 16,
        },
      },
    },
  });

  // Auto-play: avanza al siguiente slide cada 4 segundos
  useEffect(() => {
    const interval = setInterval(() => {
      instanceRef.current?.next();
    }, 4000);
    return () => clearInterval(interval);
  }, [instanceRef]);

  return (
    <section className="py-12 bg-gray-100">
      <div className="max-w-7xl mx-auto px-4 text-center mb-8">
        <h2 className="text-4xl font-bold text-black">
          Testimonios de Clientes Satisfechos
        </h2>
        <p className="text-gray-700 mt-2">
          Descubre la experiencia real de nuestros clientes y cómo hemos contribuido a su éxito.
        </p>
      </div>
      <div className="relative max-w-7xl mx-auto px-4">
        <div ref={sliderRef} className="keen-slider">
          {testimonials.map((testimonial, index) => (
            <div key={index} className="keen-slider__slide flex justify-center">
              <div className="bg-gradient-to-br from-[#8BA888] to-blue-600 rounded-lg shadow-lg p-6 flex flex-col items-center transition-transform duration-300 hover:scale-105 h-[400px] animate-fadeIn w-full max-w-[600px]">
                {/* Encabezado: perfil, nombre y empresa */}
                <div className="flex flex-col items-center mb-4">
                  <div className="bg-white rounded-full p-3 mb-2">
                    <User className="w-10 h-10 text-[#8BA888]" />
                  </div>
                  <p className="font-semibold text-white text-lg">
                    {testimonial.name}
                  </p>
                  <p className="text-blue-200 text-sm">
                    {testimonial.company}
                  </p>
                </div>
                {/* Testimonio y estrellas */}
                <div className="flex flex-col items-center mt-2 flex-grow">
                  <div className="flex mb-2">
                    {[...Array(5)].map((_, i) => (
                      <Star key={i} className="w-5 h-5 text-yellow-400 fill-current" />
                    ))}
                  </div>
                  <p className="text-white text-xl italic text-center px-2">
                    &ldquo;{testimonial.content}&rdquo;
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
        {/* Botón: Anterior */}
        <button
          title="Anterior"
          aria-label="Anterior"
          onClick={() => instanceRef.current?.prev()}
          style={{ left: "-40px" }}
          className="absolute top-1/2 transform -translate-y-1/2 bg-white rounded-full p-3 shadow-md hover:bg-gray-100 transition-colors"
        >
          <ChevronLeft className="w-6 h-6 text-[#8BA888]" />
        </button>
        {/* Botón: Siguiente */}
        <button
          title="Siguiente"
          aria-label="Siguiente"
          onClick={() => instanceRef.current?.next()}
          style={{ right: "-40px" }}
          className="absolute top-1/2 transform -translate-y-1/2 bg-white rounded-full p-3 shadow-md hover:bg-gray-100 transition-colors"
        >
          <ChevronRight className="w-6 h-6 text-[#8BA888]" />
        </button>
      </div>
      <style>{`
        @keyframes fadeIn {
          0% {
            opacity: 0;
            transform: translateY(20px);
          }
          100% {
            opacity: 1;
            transform: translateY(0);
          }
        }
        .animate-fadeIn {
          animation: fadeIn 0.5s ease-out forwards;
        }
      `}</style>
    </section>
  );
}
