import React from 'react';
import '../../styles/app.css';

interface CatalogDownloadButtonProps {
  catalog: string;
  handleDownload: (e: React.MouseEvent<HTMLAnchorElement>, catalog: string) => void;
}

const CatalogDownloadButton: React.FC<CatalogDownloadButtonProps> = ({ catalog, handleDownload }) => {
  return (
    <a
    href={catalog}
    download
    onClick={(e) => handleDownload(e, catalog)}
    className="mt-4 inline-block bg-black/20 border border-[#8BA888] text-sm px-3 py-1 rounded-full hover:scale-110 transition-transform"
    >
    <span className="light-up-text font-semibold">
      Descargar bouchur
    </span>
    </a>
  );
};

export default CatalogDownloadButton;

