import { Link } from "react-router-dom";

interface BreadcrumbsProps {
  items: { name: string; link: string }[];
}

export default function Breadcrumbs({ items }: BreadcrumbsProps) {
  return (
    <div className="text-sm text-gray-500 my-5">
      {items.map((item, index) => (
        <span key={index}>
          <Link to={item.link} className="hover:text-blue-600">
            {item.name}
          </Link>
          {index < items.length - 1 && ' / '}
        </span>
      ))}
    </div>
  );
}