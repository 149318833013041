import { Badge } from "../../../components/ui/Badge";

interface HeroSectionProps {
  title: string;
  description: string;
}

export default function HeroSection({ title, description }: HeroSectionProps) {
  return (
    <section className="relative h-[250px] bg-gradient-to-r from-[#8BA888] via-blue-600 to-blue-800">
      <div className="absolute inset-0 bg-white/10" />
      <div className="container relative z-10 h-full flex flex-col items-center justify-center text-center mx-auto">
        <Badge className="mb-4 bg-white/90 text-[#8BA888] hover:bg-white">
          Línea de Productos
        </Badge>
        <h1 className="text-3xl md:text-5xl font-bold text-white mb-4">
          {title}
        </h1>
        <p className="text-white/90 max-w-xl">
          {description}
        </p>
      </div>
    </section>
  );
}