import { useState, useEffect, useContext } from 'react';
import { ProductService } from '../services/ProductService';
import { Product } from '../types/Product';
import { ProductsContext } from '../contexts/ProductsContext';

interface UseAllProductsProps {
  selectedCategory: string;
  subcategory: string;
  activeFilters: string[];
  searchTerm?: string;
}

export const useAllProducts = ({
  selectedCategory,
  subcategory,
  activeFilters,
  searchTerm = "",
}: UseAllProductsProps) => {
  const context = useContext(ProductsContext);
  if (!context) {
    throw new Error('useAllProducts debe usarse dentro de un ProductsProvider');
  }

  const { allProducts, setAllProducts } = context;
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState<number>(1);
  const PRODUCTS_PER_PAGE = 20;

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      setError(null);
      try {
        // Si ya hay productos en el contexto, no los vuelves a cargar
        if (allProducts.length === 0) {
          const fetchedProducts = await ProductService.fetchAllProducts();
          setAllProducts(fetchedProducts);
        }
      } catch (err) {
        console.error("Error fetching products:", err);
        setError("Error al obtener los productos.");
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [allProducts.length, setAllProducts]);

  useEffect(() => {
    const applyFilters = () => {
      let tempProducts = [...allProducts];

      if (selectedCategory !== "all") {
        tempProducts = tempProducts.filter(
          product => product.category.toLowerCase() === selectedCategory.toLowerCase()
        );
      }

      if (subcategory !== "all") {
        tempProducts = tempProducts.filter(
          product => product.subcategory.toLowerCase() === subcategory.toLowerCase()
        );
      }

      if (activeFilters.length > 0) {
        // Implementa lógica de filtrado adicional si es necesario
        // Por ejemplo, filtrado por certificaciones o colores
      }

      if (searchTerm.trim() !== "") {
        tempProducts = tempProducts.filter(product =>
          product.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }

      setFilteredProducts(tempProducts);
      setPage(1); // Reinicia la paginación al aplicar filtros
    };

    applyFilters();
  }, [allProducts, selectedCategory, subcategory, activeFilters, searchTerm]);

  // Determinar los productos a mostrar según la página actual
  const displayedProducts = filteredProducts.slice(0, page * PRODUCTS_PER_PAGE);
  const hasMore = filteredProducts.length > displayedProducts.length;

  const loadMore = () => {
    setPage(prevPage => prevPage + 1);
  };

  return { filteredProducts: displayedProducts, loading, error, loadMore, hasMore };
};