import { Card, CardContent } from "../../../components/ui/Card";
import { Link } from "react-router-dom";
import NotFound from "../../../assets/images/NotFound.png";
interface RecommendedProduct {
   name: string;
  model: string;
  image: string;
}

interface RecommendedProductsProps {
  products: RecommendedProduct[];
  handleImageError: (event: React.SyntheticEvent<HTMLImageElement, Event>) => void;
  currentCategoria: string;
  currentSubcategoria: string;
}

export default function RecommendedProducts({
  products,
  handleImageError,
  currentCategoria,
  currentSubcategoria,
}: RecommendedProductsProps) {
  return (
    <section className="mt-20">
      <h2 className="text-2xl font-bold text-gray-900 mb-8">
        Explora otros productos de nuestra tienda:
      </h2>
      <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {products.map((product) => (
          <Link
            key={product.model}
            to={`/productos/${currentCategoria}/${currentSubcategoria}/${product.model}`}
          >
            <Card className="border-none shadow-lg hover:shadow-xl transition-all duration-300">
              <CardContent className="p-6">
                <div className="relative aspect-square mb-4 bg-white rounded-lg">
                  <img
                    src={product.image || NotFound}
                    alt={product.name}
                    className="object-contain p-4"
                    onError={handleImageError}
                  />
                </div>
                <h3 className="font-semibold text-gray-900">{product.name}</h3>
                <p className="text-gray-600">Modelo: {product.model}</p>
              </CardContent>
            </Card>
          </Link>
        ))}
      </div>
    </section>
  );
}