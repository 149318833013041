import React from 'react';
import { Helmet } from 'react-helmet';
import seoConfig from '../config/seoConfig';

interface SEOProps {
  title?: string;
  description?: string;
  url?: string;
  image?: string;
  imageAlt?: string;
}

const SEO: React.FC<SEOProps> = ({
  title,
  description,
  url,
  image,
  imageAlt,
}) => {
  const {
    baseURL,
    title: defaultTitle,
    description: defaultDescription,
    image: defaultImage,
    twitter,
  } = seoConfig;

  const metaTitle = title ? `${title} | ${defaultTitle}` : defaultTitle;
  const metaDescription = description || defaultDescription;
  const metaURL = url || baseURL;
  const metaImage = image || defaultImage.url;
  const metaImageAlt = imageAlt || defaultImage.alt;

  return (
    <Helmet>
      {/* Título */}
      <title>{metaTitle}</title>

      {/* Meta Descripción */}
      <meta name="description" content={metaDescription} />

      {/* Canonical URL */}
      <link rel="canonical" href={metaURL} />

      {/* Open Graph / Facebook Meta Tags */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={metaURL} />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content={metaImage} />
      <meta property="og:image:alt" content={metaImageAlt} />

      {/* Twitter Meta Tags */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={metaURL} />
      <meta property="twitter:title" content={metaTitle} />
      <meta property="twitter:description" content={metaDescription} />
      <meta property="twitter:image" content={metaImage} />
      <meta property="twitter:image:alt" content={metaImageAlt} />
      <meta property="twitter:creator" content={twitter} />
    </Helmet>
  );
};

export default SEO;