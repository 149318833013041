'use client'

import React, { useState, useRef, useEffect } from "react";
import HeaderSection from "./sections/Subcategorias/HeroSection";
import ProductGridSection from "./sections/Subcategorias/ProductGridSection";
import NotFound from "../assets/images/NotFound.png";
import { Badge } from "../components/ui/Badge";
import { useParams } from "react-router-dom";
import { useAllProducts } from '../hooks/useAllProducts';
import categories from "../data/categories"; // Importar las categorías actualizadas
import { ChevronLeft, ChevronRight } from 'lucide-react'; 
import { Button } from "../components/ui/Button";
import SEO from "../components/SEO";
import seoConfig from "../config/seoConfig";

export default function AllProductsPage() {
  const [activeFilters, setActiveFilters] = useState<string[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>("all");
  const [selectedSubcategory, setSelectedSubcategory] = useState<string>("all");
  const [searchTerm, setSearchTerm] = useState<string>(""); // Añadido estado para búsqueda

  const { filteredProducts, loading, error, loadMore, hasMore } = useAllProducts({
    selectedCategory,
    subcategory: selectedSubcategory,
    activeFilters,
    searchTerm, // Pasar searchTerm al hook
  });

  // Handler para errores de carga de imágenes
  const handleImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.src = NotFound; // Establece el placeholder
  };

  // Obtener subcategorías desde categories.ts
  const currentCategory = categories.find(cat => cat.id === selectedCategory);
  const currentSubcategories = currentCategory && currentCategory.subcategories
    ? currentCategory.subcategories.map(sub => sub.id)
    : [];

  // Ref para el contenedor de los Badges
  const badgesContainerRef = useRef<HTMLDivElement>(null);

  // Función para desplazar el carrusel hacia la izquierda
  const scrollLeft = () => {
    if (badgesContainerRef.current) {
      badgesContainerRef.current.scrollBy({
        left: -200, // Ajusta la cantidad de desplazamiento según tus necesidades
        behavior: 'smooth'
      });
    }
  };

  // Función para desplazar el carrusel hacia la derecha
  const scrollRight = () => {
    if (badgesContainerRef.current) {
      badgesContainerRef.current.scrollBy({
        left: 200, // Ajusta la cantidad de desplazamiento según tus necesidades
        behavior: 'smooth'
      });
    }
  };

  // Limpiar el caché cuando se desmonta el componente
  useEffect(() => {
    return () => {
      setSelectedCategory("all");
      setSelectedSubcategory("all");
      setActiveFilters([]);
      setSearchTerm(""); // Resetear searchTerm al desmontar
    };
  }, []);

  return (
    <div className="min-h-screen bg-white">
      <SEO
        title={selectedSubcategory && selectedSubcategory !== "all" ? `${selectedSubcategory} | Corporación Cohuin SAC` : 'Todos los Productos | Corporación Cohuin SAC'}
        description={'Explora nuestra amplia gama de productos y servicios en Corporación Cohuin SAC.'}
        url={`https://cohuin.com/productos/todos`}
        image={seoConfig.image.url}
        imageAlt={'Imagen de productos de Corporación Cohuin SAC'}
      />
      {/* Header Section en modo AllProducts */}
      <HeaderSection
        selectedCategory={selectedCategory}
        setSelectedCategory={(category: string) => {
          setSelectedCategory(category);
          setSelectedSubcategory("all"); // Resetear subcategoría al cambiar categoría
          setActiveFilters([]); // Opcional: resetear filtros al cambiar categoría
          setSearchTerm(""); // Opcional: resetear búsqueda al cambiar categoría
        }}
        searchTerm={searchTerm} // Pasar searchTerm
        setSearchTerm={setSearchTerm} // Pasar setSearchTerm
      />

      {/* Subcategory Filters */}
      {selectedCategory !== "all" && currentSubcategories.length > 0 && (
        <section className="mt-4 sm:px-0 relative">
          {/* Flecha Izquierda - Sólo en Desktop */}
          <button
            onClick={scrollLeft}
            className="hidden sm:flex absolute left-0 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 hover:bg-opacity-75 text-gray-700 rounded-full p-1 shadow-md z-10"
            aria-label="Desplazar hacia la izquierda"
          >
            <ChevronLeft size={24} />
          </button>

          {/* Contenedor de los Badges */}
          <div
            ref={badgesContainerRef}
            className="flex items-center overflow-x-auto gap-3 justify-start sm:justify-start hide-scrollbar sm:px-8 snap-x snap-mandatory"
          >
            {currentSubcategories.map((subcat) => (
              <Badge
                key={subcat}
                className={`${
                  selectedSubcategory === subcat
                    ? 'bg-blue-600 border-b-2 border-blue-600'
                    : 'bg-[#8BA888]'
                } text-white text-sm sm:text-base cursor-pointer px-3 py-1 rounded-full transition-colors duration-200 flex-none snap-start`}
                onClick={() => setSelectedSubcategory(subcat)}
              >
                {subcat.charAt(0).toUpperCase() + subcat.slice(1)}
              </Badge>
            ))}
            <Badge
              className={`${
                selectedSubcategory === "all"
                  ? 'bg-blue-600 border-b-2 border-blue-600'
                  : 'bg-[#8BA888]'
              } text-white text-sm sm:text-base cursor-pointer px-3 py-1 rounded-full transition-colors duration-200 flex-none snap-start`}
              onClick={() => setSelectedSubcategory("all")}
            >
              Todas
            </Badge>
          </div>

          {/* Flecha Derecha - Sólo en Desktop */}
          <button
            onClick={scrollRight}
            className="hidden sm:flex absolute right-0 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 hover:bg-opacity-75 text-gray-700 rounded-full p-1 shadow-md z-10"
            aria-label="Desplazar hacia la derecha"
          >
            <ChevronRight size={24} />
          </button>
        </section>
      )}

      {/* Product Grid Section */}
      <ProductGridSection
        products={filteredProducts}
        loading={loading}
        handleImageError={handleImageError}
      />

      {/* Botón para cargar más productos */}
      {hasMore && !loading && (
        <div className="flex justify-center my-6">
          <Button
            onClick={loadMore}
            className="px-4 py-2 bg-blue-600 text-white rounded-[30px] hover:bg-blue-700 transition"
          >
            Ver más productos
          </Button>
        </div>
      )}
    </div>
  );
}