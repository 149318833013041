import React from 'react';
import { motion } from 'framer-motion';
import AesaLogo from '../../../assets/images/logos/AESA.png';
import Atlabtic from '../../../assets/images/logos/ATLABTIC LOGO.png';
import BuenaventuraLogo from '../../../assets/images/logos/BUENAVENTURA.png';
import ConfipetrolLogo from '../../../assets/images/logos/CONFIPETROL.png';
import EcosemuqLogo from '../../../assets/images/logos/ECOSEMUQ.png';
import EcosermoLogo from '../../../assets/images/logos/ECOSERMO.png';
import JRCLogo from '../../../assets/images/logos/JRC.png';
import MasterDrillingLogo from '../../../assets/images/logos/MASTER DRILLING.png';
import MeIngYConsLogo from '../../../assets/images/logos/ME ING Y CONS.png';
import MinsurLogo from '../../../assets/images/logos/MINSUR.png';
import RauraLogo from '../../../assets/images/logos/RAURA.png';
import RedrilsaLogo from '../../../assets/images/logos/REDRILSA.png';
import ReseferLogo from '../../../assets/images/logos/RESEFER.png';
import RockDrillingLogo from '../../../assets/images/logos/ROCK DRILL.png';
import ZicsaLogo from '../../../assets/images/logos/ZICSA.png';
import IncimmetLogo from '../../../assets/images/logos/INCIMMET.png';
import OverprimeLogo from '../../../assets/images/logos/OVERPRIME.png';
import OptimusLogo from '../../../assets/images/logos/OPTIMUS.png';
import ElAlisoLogo from '../../../assets/images/logos/EL ALISO.png';
import CaraveliLogo from '../../../assets/images/logos/CARAVELI.png';
import CoimolacheLogo from '../../../assets/images/logos/COIMOLACHE.png';
import MulticosailorLogo from '../../../assets/images/logos/MULTICOSAILOR.png';
import AmericarentacarLogo from '../../../assets/images/logos/AMERICARENTACAR.png';

const clients = [
  { name: "Aesa", logo: AesaLogo },
  { name: "Atlabtic", logo: Atlabtic },
  { name: "Buenaventura", logo: BuenaventuraLogo },
  { name: "Confipetrol", logo: ConfipetrolLogo},
  { name: "Ecosemuq", logo: EcosemuqLogo},
  { name: "Ecosermo", logo: EcosermoLogo },
  { name: "JRC", logo: JRCLogo},
  { name: "Master drilling", logo: MasterDrillingLogo },
  { name: "Me ing y cons", logo: MeIngYConsLogo},
  { name: "Minsur", logo:  MinsurLogo},
  { name: "Raura", logo: RauraLogo},
  { name: "Redrilsa", logo: RedrilsaLogo},
  { name: "Resefer", logo: ReseferLogo},
  { name: "Rock drill", logo: RockDrillingLogo },
  { name: "Zicsa", logo: ZicsaLogo },
  { name: "Incimmet", logo: IncimmetLogo },
  { name: "Overprime", logo: OverprimeLogo },
  { name: "Optimus", logo: OptimusLogo },
  { name: "El aliso", logo: ElAlisoLogo },
  { name: "Caraveli", logo: CaraveliLogo },
  { name: "Coimolache", logo: CoimolacheLogo },
  { name: "Multicosailor", logo: MulticosailorLogo },
  { name: "Americarentarcar", logo: AmericarentacarLogo },
];

const logoVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: (custom: { delay: number }) => ({
    opacity: 1,
    transition: {
      duration: 0.6,
      ease: "easeOut",
      delay: custom.delay,
    },
  }),
};

const ClientsLogosSection: React.FC = () => {
  // Supongamos 4 columnas en pantallas medianas (md)
  const columns = 4;
  const baseDelay = 0.3; // Retardo base por fila

  return (
    <section className="py-16 bg-white">
      <div className="container mx-auto max-w-7xl px-4">
        <h2 className="text-3xl sm:text-4xl font-bold text-center mb-12">Nuestros Clientes</h2>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-8 items-center">
          {clients.map((client, index) => {
            // Calcular la fila basada en el número de columnas
            const rowNumber = Math.floor(index / columns);
            const delay = rowNumber * baseDelay; // Incrementar el retardo por fila

            return (
              <motion.div
                key={index}
                className="flex justify-center items-center p-4 transition-transform transform hover:scale-110"
                variants={logoVariants}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.3 }}
                custom={{ delay }}
              >
                <img
                  src={client.logo}
                  alt={client.name}
                  className="h-28 sm:h-32 md:h-40 object-contain hover:scale-125"
                />
              </motion.div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default ClientsLogosSection;