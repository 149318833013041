import React from 'react';
import { Card, CardContent } from "../../../components/ui/Card";
import MissionImage from '../../../assets/images/mision.jpg'; // Reemplaza con la ruta correcta
import VisionImage from '../../../assets/images/vision.jpg';   // Reemplaza con la ruta correcta

const MissionVisionSection: React.FC = () => {
  return (
    <section className="py-16 bg-gradient-to-b from-white to-[#8BA888]/5">
      <div className="container mx-auto max-w-4xl">
        <div className="grid grid-cols-1 gap-8 items-center">
          
          {/* Nuestra Misión */}
          <Card className="border-none shadow-lg bg-gradient-to-br from-[#8BA888]/10 to-white overflow-hidden rounded-[30px]">
            <div className="grid grid-cols-1 md:grid-cols-2 items-center">
              {/* Imagen a la izquierda */}
              <div className="relative h-48 md:h-full">
                <img
                  src={MissionImage}
                  alt="Nuestra Misión"
                  className="object-cover w-full h-full rounded-[30px]"
                />
              </div>
              {/* Texto a la derecha */}
              <CardContent className="p-8">
                <h2 className="text-2xl font-semibold mb-6 text-[#8BA888]">
                  Nuestra Misión
                </h2>
                <p className="text-gray-600 leading-relaxed">
                Nos dedicamos a satisfacer las necesidades de nuestros clientes mediante un enfoque integral y personalizado. Nuestro equipo altamente capacitado se compromete a brindar soluciones de alta calidad en ferretería, señalización y EPP, estableciendo relaciones comerciales duraderas basadas en la confianza y la satisfacción. En Corporación Cohuin SAC, trabajamos para ofrecer un servicio excepcional que apoye el éxito de nuestros clientes y fortalezca nuestra comunidad.
                </p>
              </CardContent>
            </div>
          </Card>

          {/* Nuestra Visión */}
          <Card className="border-none shadow-lg bg-gradient-to-br from-blue-50 to-white overflow-hidden rounded-[30px]">
            <div className="grid grid-cols-1 md:grid-cols-2 items-center">
              {/* Texto a la izquierda */}
              <CardContent className="p-8">
                <h2 className="text-2xl font-semibold mb-6 text-blue-700">
                  Nuestra Visión
                </h2>
                <p className="text-gray-600 leading-relaxed">
                  En Corporación Cohuin SAC, aspiramos a ser el principal proveedor nacional de productos 
                  de ferretería, equipos de protección personal (EPP) y señalización vial y minera. 
                  Nuestro objetivo es liderar el mercado ofreciendo las mejores soluciones para empresas 
                  mineras, industriales y el público en general, contribuyendo al desarrollo de la sociedad 
                  a través de la excelencia en nuestros productos y servicios.
                </p>
              </CardContent>
              {/* Imagen a la derecha */}
              <div className="relative h-48 md:h-full">
                <img
                  src={VisionImage}
                  alt="Nuestra Visión"
                  className="object-cover w-full h-full rounded-[30px]"
                />
              </div>
            </div>
          </Card>

        </div>
      </div>
    </section>
  );
};

export default MissionVisionSection;