'use client'

import { useParams } from "react-router-dom";
import Breadcrumbs from "./sections/ProductoDetalleSection/Breadcrumbs";
import ImageGallery from "./sections/ProductoDetalleSection/ImageGallery";
import ProductInfo from "./sections/ProductoDetalleSection/ProductInfo";
import ProductDetailsAccordion from "./sections/ProductoDetalleSection/ProductDetailsAccordion";
import RecommendedProducts from "./sections/ProductoDetalleSection/RecommendedProducts";
import CotizacionForm from "./sections/ProductoDetalleSection/CotizacionForm";
import { Button } from "../components/ui/Button"; // Asegúrate de ajustar la ruta según tu estructura
import NotFound from "../assets/images/NotFound.png";
// Elimina esta línea
// import { allProducts, Product } from '../data/allProducts';
import { Card } from "../components/ui/Card"; // Asegúrate de tener un componente Card
import React, { useState, useRef } from "react";
import phoneNumbers from '../data/phoneNumbers';
import { useProduct } from '../hooks/useProduct'; // Importar el nuevo hook
import { useFilteredProducts } from '../hooks/useFilteredProducts'; // Importar el hook para productos filtrados
import { Product } from '../types/Product'; // Importar la interfaz Product
import SEO from '../components/SEO'; 
import seoConfig from "../config/seoConfig";

interface RecommendedProduct {
  name: string;
  image: string;
  model: string;
}

export default function ProductDetailPage() {
  const { categoria, subcategoria, model } = useParams<{ categoria: string; subcategoria: string; model: string }>();
  
  // Elevar el estado al componente padre
  const [agregarMensajeAdicional, setAgregarMensajeAdicional] = useState(false);
  
  // Crear una referencia al formulario
  const formRef = useRef<HTMLFormElement>(null);

  // Usar el hook personalizado para obtener el producto
  const { product, loading, error } = useProduct({ model });

  // Definir las categorías y subcategorías por defecto
  const selectedCategory = product ? product.category : "all";
  const selectedSubcategory = product ? product.subcategory : "all";

  // Llamar al hook useFilteredProducts de forma incondicional
  const { filteredProducts, loading: loadingFiltered, error: errorFiltered } = useFilteredProducts({
    selectedCategory,
    selectedSubcategory,
    activeFilters: [], // Ajusta según tus necesidades
  });

  // Obtener el producto recomendado de la misma categoría y subcategoría, excluyendo el actual
  const recommendedProducts: RecommendedProduct[] = filteredProducts
    .filter(
      (p) => p.category === selectedCategory && p.subcategory === selectedSubcategory && p.model !== model
    )
    .slice(0, 3)
    .map(p => ({
      name: p.name,
      image: p.image,
      model: p.model,
    }));

  // Handler para errores de carga de imágenes
  const handleImageError = (e: React.SyntheticEvent<HTMLImageElement, Event>): void => {
    e.currentTarget.src = NotFound;
  };

  // Definir los breadcrumbs basados en el producto
  const breadcrumbsItems = product ? [
    { name: "Productos", link: "/productos" },
    { name: capitalizeFirstLetter(product.category), link: `/productos/${product.category.toLowerCase()}` },
    { name: capitalizeFirstLetter(product.subcategory), link: `/productos/${product.category.toLowerCase()}/${product.subcategory.toLowerCase()}` },
    { name: product.name, link: `/productos/${product.category.toLowerCase()}/${product.subcategory.toLowerCase()}/${product.model}` },
  ] : [
    { name: "Productos", link: "/productos" },
  ];

  // Definir los detalles del producto basados en la descripción
  const details = product ? {
    description: [product.description], // Convertir a array
  } : {
    description: [],
  };

  // Función para manejar el envío del formulario
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    // Obtener el valor del mensaje predeterminado
    const mensajePredeterminadoElement = document.getElementById("mensajePredeterminado") as HTMLSelectElement | null;
    const mensajePredeterminado = mensajePredeterminadoElement ? mensajePredeterminadoElement.value : "";

    // Obtener el valor del mensaje adicional si está seleccionado
    const mensajeAdicional = agregarMensajeAdicional
      ? ((document.getElementById("mensaje") as HTMLTextAreaElement) || { value: "" }).value
      : "";

    // Construir el mensaje final sin duplicar el mensaje predeterminado
    let mensajeFinal = mensajePredeterminado;
    if (mensajeAdicional.trim() !== '') {
      mensajeFinal += `.  ${mensajeAdicional}`;
    }

    console.log(mensajeFinal); // Verificar el mensaje en la consola

    // Obtener el número de cotización 1 desde phoneNumbers.tsx
    const phoneNumber = phoneNumbers.find(pn => pn.name === 'Liz Samar')?.number || '950284447';

    // Construir la URL de WhatsApp
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(mensajeFinal)}`;
    
    // Abrir WhatsApp en una nueva pestaña
    window.open(whatsappUrl, "_blank");
  };

  // Si está cargando el producto
  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <h1 className="text-2xl font-semibold">Cargando producto...</h1>
      </div>
    );
  }

  // Si hay un error o el producto no se encuentra
  if (error || !product) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <h1 className="text-2xl font-semibold">{error || "Producto no encontrado"}</h1>
      </div>
    );
  }

  // Definir las imágenes del producto, asegurando al menos una imagen
  const images = product.image ? [product.image] : [NotFound];

  return (
    <div className="min-h-screen bg-gray-50/70 mx-3">
      <SEO
        title={product.name}
        description={product.description || "Detalles del producto"}
        url={`${seoConfig.baseURL}/productos/${product.category}/${product.subcategory}/${product.model}`}
        image={product.image || seoConfig.image.url}
        imageAlt={product.name}
      />
      <div className="container max-w-7xl py-2 mx-auto">
        {/* Breadcrumb */}
        <Breadcrumbs items={breadcrumbsItems} />

        {/* Product Section */}
        <div className="flex flex-col lg:flex-row-reverse gap-5 sm:gap-12 pt-5">
          {/* Imágenes a la derecha en Desktop y arriba en Móvil */}
          <div className="lg:w-1/2 sm:w-auto">
            <ImageGallery
              images={images}
              productName={product.name}
              handleImageError={handleImageError}
            />
          </div>

          {/* Información y Detalles en un Card a la izquierda en Desktop y abajo en Móvil */}
          <div className="lg:w-1/2">
            <Card className="p-6">
              <ProductInfo
                name={product.name}
                code={product.model}
                category={product.category}
              />

              <ProductDetailsAccordion details={details} />
            </Card>

            <Card className="space-y-6 p-4 bg-white shadow-md mt-6">
              {/* Formulario de Cotización siempre visible */}
              <CotizacionForm
                productName={product.name}
                handleSubmit={handleSubmit}
                agregarMensajeAdicional={agregarMensajeAdicional} 
                setAgregarMensajeAdicional={setAgregarMensajeAdicional} 
              />
            </Card>
          </div>
        </div>

        {/* Recommended Products */}
        <RecommendedProducts
          products={recommendedProducts}
          handleImageError={handleImageError}
          currentCategoria={product.category}
          currentSubcategoria={product.subcategory}
        />
      </div>
    </div>
  );
}

// Función para capitalizar la primera letra de una cadena
function capitalizeFirstLetter(word: string): string {
  if (!word) return '';
  return word.charAt(0).toUpperCase() + word.slice(1);
}