import React from 'react';
import { Badge } from "../../../components/ui/Badge";
import AboutImage from '../../../assets/images/about-image.jpg'; // Asegúrate de tener esta imagen

const AboutSection: React.FC = () => {
  return (
    <section className="py-16">
      <div className="container mx-auto max-w-5xl">
        <div className="text-center mb-12 animate-fadeIn">
          <Badge className="mb-8 bg-gradient-to-r from-[#8BA888] to-blue-600 text-white">
            Quienes Somos
          </Badge>
          <h2 className="sm:text-3xl text-3xl md:text-[75px] font-semibold sm:mb-14 mb-6 text-gray-900 animate-slideUp">
            Corporación Cohuin SAC
          </h2>
          <p className="text-gray-800 md:text-2xl leading-relaxed text-center animate-fadeIn delay-200">
            Corporación Cohuin SAC es un proveedor integral especializado en productos de ferretería, señalizaciones viales y mineras, y equipos de protección personal (EPP). Ofrecemos soluciones de alta calidad para diversas industrias, con un enfoque en la seguridad y la adaptabilidad a las necesidades de nuestros clientes. Nuestro compromiso con la excelencia nos ha consolidado como un socio confiable para empresas mineras e industriales.
          </p>
        </div>   
      </div>
      <div className="container mx-auto max-w-6xl mt-20">
        <img
          src={AboutImage}
          alt="Imagen de Corporación Cohuin SAC"
          className="sm:w-full rounded-[30px] mt-8 animate-slideInRight"
        />
      </div>
    </section>
  );
};

export default AboutSection;