import React from 'react';
import NotFoundImage from '../assets/images/NotFound.png';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

export default function NotFoun() {
  return (
    <div className='justify-center bg-gradient-to-r from-[#8BA888]/10 to-blue-50'>
    
    <div className="flex flex-col items-center pt-7">
      <img src={NotFoundImage} alt="404 Not Found" className="mb-8 w-64" />
      <h1 className="text-4xl md:text-6xl font-extrabold text-gray-900 mb-4">404</h1>
      <p className="text-xl md:text-2xl text-gray-600 mb-6">Página no encontrada</p>
      <div className="flex items-center space-x-2">
      <a href="/" className="pt-5 text-3xl hover:text-blue-500">Vuelve al Inicio</a>
      <p className="text-3xl pt-5">o explora nuestros productos.</p>
      </div>
    </div>

<div className=" max-w-6xl  mx-auto py-10">
      {/* Icon Grid Section */}
    <motion.div
      className="grid grid-cols-2 gap-6"
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      variants={{
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0 },
      }}
      transition={{ duration: 0.6 }}
    >
      {/* EPP */}
      <Link to="/productos/Equipos%20de%20Protección%20Personal%20(EPP)">
        <motion.div
          className="bg-gradient-to-br from-green-500 to-green-700 flex justify-center items-center h-32 md:h-40 w-full rounded-xl shadow-lg"
          whileHover={{ scale: 1.05 }}
        >
          <h3 className="text-white text-base md:text-xl font-bold">EPP</h3>
        </motion.div>
      </Link>
      {/* Señalización */}
      <Link to="/productos/Señalización">
        <motion.div
          className="bg-gradient-to-br from-blue-500 to-blue-700 flex justify-center items-center h-32 md:h-40 w-full rounded-xl shadow-lg"
          whileHover={{ scale: 1.05 }}
        >
          <h3 className="text-white text-base md:text-xl font-bold">Señalización</h3>
        </motion.div>
      </Link>
      {/* Ferretería */}
      <Link to="/productos/Ferretería">
        <motion.div
          className="bg-gradient-to-br from-purple-500 to-purple-700 flex justify-center items-center h-32 md:h-40 w-full rounded-xl shadow-lg"
          whileHover={{ scale: 1.05 }}
        >
          <h3 className="text-white text-base md:text-xl font-bold">Ferretería</h3>
        </motion.div>
      </Link>
      {/* Seguridad */}
      <Link to="/productos/Servicios">
        <motion.div
          className="bg-gradient-to-br from-yellow-500 to-yellow-700 flex justify-center items-center h-32 md:h-40 w-full rounded-xl shadow-lg"
          whileHover={{ scale: 1.05 }}
        >
          <h3 className="text-white text-base md:text-xl font-bold">Seguridad</h3>
        </motion.div>
      </Link>
    </motion.div>
    </div>
    </div>
  );
}