import React from 'react';
import { useParams } from 'react-router-dom';
import SubcategoriesGridSection from "./sections/CategoriaSections/SubcategoriesGridSection";
import Placeholder from '../assets/images/Placeholder.svg';
import categories from '../data/categories'; 
import BrandCarousel from '../components/BrandCarousel';
import { brands, Brand } from '../data/brands';
import HeroSection from './sections/CategoriaSections/HeroSection';
import SEO from '../components/SEO';
import seoConfig from '../config/seoConfig';
// Handler para errores de carga de imágenes
const handleImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
  event.currentTarget.src = Placeholder; // Establece el placeholder
};

export default function CategoryPage() {
  // Obtener el parámetro de la ruta
  const { categoria } = useParams<{ categoria: string }>();

  // Encontrar la categoría seleccionada
  const category = categories.find(cat => cat.id.toLowerCase() === categoria?.toLowerCase());

  // Filtrar marcas según la categoría seleccionada
  const filteredBrands = brands.filter(brand => brand.category === category?.id);

  // Categorías válidas para mostrar el carrusel
  const validCarouselCategories = ['Ferretería', 'Equipos de Protección Personal (EPP)'];

  // Verificar si la categoría es válida para mostrar el carrusel
  const isValidCategory = category && validCarouselCategories.includes(category.id);

  return (
    <div>
      <SEO
          title={category?.title || 'Categorias'}
          description={category?.description || 'Explora nuestra amplia gama de productos y servicios en Corporación Cohuin SAC.'}
          url={`https://cohuin.com/productos/${categoria}`}
          image={seoConfig.image.url}
          imageAlt={'Imagen de productos de Corporación Cohuin SAC'}
      />
      {/* Header Section */}
      <HeroSection title={category?.title || 'Default Title'} description={category?.description || 'Default Description'} />

      {/* Subcategories Grid Section */}
      {category && (
        <SubcategoriesGridSection
          categoria={category.id} // Pasar el ID de la categoría como string
          subcategories={category.subcategories || []}
          handleImageError={handleImageError}
        />
      )}

      {/* Mostrar BrandCarousel solo para categorías válidas */}
      {isValidCategory && filteredBrands.length > 0 && (
        <div className="container mx-auto px-4 py-16 bg-white rounded-t-[30px]">
          <h1 className="text-3xl font-bold text-center text-gray-700 mb-12">Nuestras Marcas Asociadas</h1>
          <BrandCarousel brands={filteredBrands} className="mb-16" />
          <p className="text-center text-gray-600 max-w-2xl mx-auto">
            Trabajamos con las mejores marcas del mercado para ofrecerte productos de alta calidad y rendimiento.
          </p>
        </div>
      )}
    </div>
  )
}