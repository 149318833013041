import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
  } from "../../../components/ui/Accordion";
  
  interface ProductDetailsAccordionProps {
    details: {
      description: string[];
 
    };
  }
  
  export default function ProductDetailsAccordion({ details }: ProductDetailsAccordionProps) {
    return (
      <Accordion type="single" collapsible className="w-full">
       
  
        <AccordionItem value="specifications">
          <AccordionTrigger className="text-xl font-semibold">
            Especificaciones
          </AccordionTrigger>
          <AccordionContent>
            <ul className="list-disc text-lg list-inside text-gray-600 space-y-2">
              {details.description.map((spec, index) => (
                <li key={index}>{spec}</li>
              ))}
            </ul>
          </AccordionContent>
        </AccordionItem>
  
 
      </Accordion>
    );
  }