
import HeroSection from "../components/ui/HeroSection"
import MainContentSection from "./sections/ClientesSections/MainContentSection"
import StatisticsSection from "./sections/ClientesSections/StatisticsSection"
import ClientsLogosSection from "./sections/ClientesSections/ClientsLogosSection"
import SEO from "../components/SEO"
import seoConfig from "../config/seoConfig"


export default function ClientsPage() {
  return (
    <div className="min-h-screen bg-white">
      <SEO
        title= 'Nuestros Clientes'
        description='Todo sobre nuestros clientes'
        url={`https://cohuin.com/nuestros-clientes`}
        image={seoConfig.image.url}
        imageAlt={'Imagen de productos de Corporación Cohuin SAC'}
      />
       {/* Hero Section */}
       <HeroSection title="Nuestros Clientes"  />
<div className="mx-3">
      {/* Main Content Section */}
      <MainContentSection />

      {/* Statistics Section */}
      <StatisticsSection />

      {/* Clients Logos Section */}
      <ClientsLogosSection />
    </div>
    </div>
  )
}

