import React, { useState, useEffect, useRef } from 'react';
import { FaWhatsapp, FaTimes } from 'react-icons/fa';
import phoneNumbers from '../data/phoneNumbers';

interface WhatsAppFloatButtonProps {
  message: string;
}

interface Contact {
  name: string;
  number: string;
  message: string;
  label: string;
}

const WhatsAppFloatButton: React.FC<WhatsAppFloatButtonProps> = ({ message }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const cardRef = useRef<HTMLDivElement>(null);

  const handleWhatsAppClick = (number: string, message: string): void => {
    const whatsappUrl = `https://wa.me/${number}?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, '_blank');
    setIsOpen(false); // Cierra el menú después de seleccionar
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      cardRef.current &&
      !cardRef.current.contains(event.target as Node) &&
      !(event.target as Element).closest('.fixed.bottom-6.right-6.cursor-pointer')
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="relative z-50">
      {/* Botón flotante de WhatsApp */}
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="fixed bottom-6 right-6 cursor-pointer"
        aria-label={isOpen ? 'Cerrar menú de WhatsApp' : 'Abrir menú de WhatsApp'}
        role="button"
        tabIndex={0}
        onKeyPress={() => setIsOpen(!isOpen)}
      >
        <div
          className={`bg-green-500 text-white rounded-full p-4 shadow-lg hover:bg-green-600 hover:scale-125 transition-transform duration-300 flex items-center justify-center ${
            isOpen ? 'bg-red-500' : 'bg-green-500'
          }`}
        >
          {isOpen ? <FaTimes size={24} /> : <FaWhatsapp size={24} />}
        </div>
      </div>

      {/* Card flotante con animaciones */}
      <div
        ref={cardRef}
        className={`fixed bottom-20 right-6 z-40 shadow-lg rounded-lg p-6 bg-white border border-gray-200 w-72 
                    transform transition-all duration-300 ease-in-out 
                    ${
                      isOpen
                        ? 'opacity-100 translate-y-0 pointer-events-auto'
                        : 'opacity-0 translate-y-4 pointer-events-none'
                    }`}
      >
        <h2 className="text-lg font-semibold mb-4 text-center text-green-600 animate__animated animate__fadeInDown">
          Contacta a Nuestros Miembros
        </h2>
        <div className="flex flex-col gap-3">
          {phoneNumbers.map((contact: Contact) => (
            <div
              key={contact.number}
              onClick={() => handleWhatsAppClick(contact.number, contact.message)}
              className="flex items-start gap-3 cursor-pointer bg-green-100 hover:bg-green-200 p-3 rounded transition-colors duration-300 animate__animated animate__fadeInUp"
            >
              <FaWhatsapp size={20} className="text-green-500 mt-1" />
              <div>
                <p className="font-medium text-gray-800">{contact.name}</p>
                <p className="text-sm text-gray-500">{contact.label}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhatsAppFloatButton;