interface ContactCardProps {
  title: string;
  address: string;
  phone: string;
  email: string;
  days: string;
  mapSrc: string;
}

const ContactCard: React.FC<ContactCardProps> = ({ title, address, phone, email, days, mapSrc }) => {
  return (
    <div className="shadow-md rounded-lg p-6 flex flex-col lg:flex-row bg-white border border-gray-200">
      <div className="flex-1">
        <h2 className="text-2xl font-semibold text-blue-800">{title}</h2>
        <p className="mt-2 text-gray-800"><strong>Dirección:</strong> {address}</p>
        <p className="mt-2 text-gray-800"><strong>Teléfono:</strong> {phone}</p>
        <p className="mt-2 text-gray-800"><strong>Email:</strong> <a href={`mailto:${email}`} className="text-blue-600 hover:underline">{email}</a></p>
        <p className="mt-2 text-gray-800"><strong>Horario:</strong> {days}</p>
        {/* Botón de WhatsApp con enlace dinámico */}
        <a
          href={`https://wa.me/${phone.replace(/\D/g, '')}`} // Elimina caracteres no numéricos para el enlace
          target="_blank"
          rel="noopener noreferrer"
          className="mt-4 bg-teal-500 text-white py-2 px-4 rounded-lg hover:bg-teal-600 inline-block"
        >
          WhatsApp
        </a>
      </div>
      <div className="flex-1 mt-6 lg:mt-0 lg:ml-6">
        <iframe
          src={mapSrc}
          width="100%"
          height="200"
          style={{ border: 0 }}
          allowFullScreen={true}
          loading="lazy"
          title={title}
        ></iframe>
      </div>
    </div>
  );
};

export default ContactCard;
