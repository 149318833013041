import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Card, CardContent } from "../../../components/ui/Card";
import { Badge } from "../../../components/ui/Badge";

interface TimelineEvent {
  year: string;
  description: string;
  color: string;
}

const timelineEvents: TimelineEvent[] = [
  {
    year: "2015",
    description: "Corporación Cohuin SAC inició como un negocio familiar en la provincia de Oyón, Lima, dedicándose en sus primeros años a abastecer principalmente a empresas mineras locales. Esto le permitió establecer una sólida reputación en la comunidad, destacándose por su compromiso con la calidad.",
    color: "#8BA888",
  },
  {
    year: "2016-2018",
    description: "Durante esos años, la empresa consolidó su presencia local, enfocándose en el crecimiento y la optimización de sus operaciones. Su oferta de productos y servicios de alta calidad le permitió ganar la confianza de las mineras en Oyón y sus alrededores.",
    color: "#3B82F6", 
  },
  {
    year: "2019",
    description: "Con un crecimiento constante y la creciente demanda de sus productos y servicios, la empresa se expandió a Lima, ampliando su cobertura nacional y comenzando a abastecer a empresas de renombre en los sectores minero e industrial de todo el Perú.",
    color: "#8BA888",
  },
  {
    year: "2020-2024",
    description: "Durante esos años, Corporación Cohuin SAC continuó expandiendo su presencia y consolidando relaciones con clientes clave en diversas industrias. Su enfoque en la calidad y la satisfacción del cliente le permitió diversificar su base de clientes y ampliar su oferta de productos y servicios.",
    color: "#3B82F6", 
  },
  {
    year: "Actualidad",
    description: "Hoy, Corporación Cohuin SAC se destaca como un proveedor clave, reconocido por su compromiso con la calidad y la satisfacción del cliente. La empresa continúa fortaleciendo su presencia nacional y buscando nuevas oportunidades de crecimiento.",
    color: "#8BA888",
  },
];

const TimelineSection: React.FC = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <section className="py-16 bg-gradient-to-b from-[#8BA888]/5 to-white">
      <div className="container mx-auto max-w-4xl">
        <Card className="border-none shadow-lg">
          <CardContent className="p-8 md:p-12">
            <Badge className="mb-4 bg-gradient-to-r from-[#8BA888] to-blue-600 text-white">
              Nuestra Historia
            </Badge>
            <h2 className="text-2xl md:text-3xl font-semibold mb-6 text-gray-900">
              Trayectoria
            </h2>
            <div className="space-y-8">
              {timelineEvents.map((event, index) => (
                <div
                  key={index}
                  className="relative pl-8 border-l-2"
                  style={{ borderColor: event.color }}
                  data-aos="fade-up"
                >
                  <div
                    className="absolute w-4 h-4 rounded-full -left-[9px] top-0"
                    style={{ backgroundColor: event.color }}
                  />
                  <h3
                    className="text-lg font-semibold mb-2"
                    style={{ color: event.color === "#8BA888" ? "#8BA888" : "#3B82F6" }}
                  >
                    {event.year}
                  </h3>
                  <p className="text-gray-600 text-sm sm:text-base">
                    {event.description}
                  </p>
                </div>
              ))}
            </div>
          </CardContent>
        </Card>
      </div>
    </section>
  );
};

export default TimelineSection;