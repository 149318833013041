'use client';
import React, { useEffect, useState, useRef } from 'react';
import { motion } from 'framer-motion'; // Importar Framer Motion
import { Link } from 'react-router-dom';
import SEO from '../components/SEO';
import seoConfig from '../config/seoConfig';
import TestimonialCarousel from '../components/TestimonialCarousel';
const HomePage: React.FC = () => {
  const cards = [
    {
      title: 'Calidad Garantizada',
      description: 'Ofrecemos productos de alta calidad y normativas certificadas, asegurando durabilidad y seguridad en cada proyecto.',
      link: '/productos/Equipos%20de%20Protección%20Personal%20(EPP)',
      buttonLabel: 'Conoce nuestros EPPs'
    },
    {
      title: 'Variedad y Disponibilidad',
      description: 'Contamos con un amplio catálogo de señalizaciones viales y mineras, EPP y ferretería, todo en un solo lugar para tu conveniencia.',
      link: '/productos/todos',
      buttonLabel: 'Conoce nuestros productos'
    },
    {
      title: 'Atención Personalizada',
      description: 'Nuestro equipo de expertos está siempre listo para asesorarte y ayudarte a encontrar las mejores soluciones para tus necesidades.',
      link: '/contacto',
      buttonLabel: 'Contáctanos'
    },
    {
      title: 'Compromiso con la Seguridad',
      description: 'Priorizamos la seguridad de tus trabajadores, brindando productos que minimizan riesgos y fomentan un entorno laboral seguro.',
      link: '/productos/Servicios',
      buttonLabel: 'Explora nuestros servicios'
    }
  ];
  
  const slides = [
    {
      title: 'Servicios',
      description: 'Ofrecemos soluciones completas en protección y señalización para diversas necesidades.',
      imageUrl: '/images/inicio/ferreteria3.png',
    },
    {
      title: 'Ferretería',
      description: 'Productos de calidad para todos tus proyectos de construcción y mantenimiento.',
      imageUrl: '/images/inicio/ferreteria2.png',
    },
    {
      title: 'Señalización',
      description: 'Proveemos señalización eficaz para garantizar la seguridad en diferentes entornos.',
      imageUrl: '/images/inicio/ferreteria.png',
    },
    {
      title: 'Equipos de protección personal (EPP)',
      description: 'Contamos con los mejores equipos para tu seguridad en cualquier actividad laboral.',
      imageUrl: '/images/inicio/ferreteria4.png',
    },

  ];
  
  const [currentSlide, setCurrentSlide] = useState(0);
  const carouselRef = useRef<HTMLDivElement>(null);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  
  useEffect(() => {
    if (carouselRef.current) {
      const slideWidth = carouselRef.current.offsetWidth;
      carouselRef.current.scrollLeft = currentSlide * slideWidth;
    }
  }, [currentSlide]);

  const handlePrevSlide = () => {
    setCurrentSlide((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
    resetAutoSlide();
  };

  const handleNextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
    resetAutoSlide();
  };

  const resetAutoSlide = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current); // Limpia el temporizador previo
    }
    startAutoSlide();
  };

  const startAutoSlide = () => {
    timerRef.current = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % slides.length);
    }, 5000); // Cambiar slide cada 5 segundos
  };

  useEffect(() => {
    startAutoSlide(); // Inicia el auto-desplazamiento al montar el componente
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current); // Limpia el temporizador al desmontar
      }
    };
  }, []);
  

  return (
    <div className="min-h-screen bg-gray-50">
      <SEO
         title="Inicio"
         description="Bienvenido a Corporación Cohuin SAC, descubre nuestros productos y servicios."
         url="https://cohuin.com/inicio"
         image={seoConfig.image.url}
         imageAlt="Corporación Cohuin SAC - Inicio"
      />
            <section className="relative bg-cover bg-center h-screen flex items-center m-0 p-0 overflow-hidden">
        <motion.div
          key={currentSlide}
          className="absolute inset-0"
          style={{
            backgroundImage: `url(${slides[currentSlide].imageUrl})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5, ease: 'easeInOut' }}
        />
        <div className="absolute inset-0 bg-black/60"></div>
        <div className="container mx-auto relative z-10 flex items-center px-4 sm:px-6 lg:px-8">
          <motion.div
            className="max-w-lg"
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.2, duration: 1 }}
          >
            <h1 className="text-3xl sm:text-4xl md:text-6xl font-bold text-white">
              {slides[currentSlide].title}
            </h1>
            <p className="text-base sm:text-lg md:text-xl text-gray-300 font-light mt-4">
              {slides[currentSlide].description}
            </p>
            <motion.div
              className="flex flex-wrap gap-4 mt-6"
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4, duration: 0.8 }}
            >
              <Link to="/productos">
                <button className="bg-blue-600 hover:bg-blue-700 focus:bg-blue-800 active:bg-blue-900 transition-all text-white px-6 py-3 rounded-md font-medium w-full md:w-auto">
                  Conoce nuestros productos
                </button>
              </Link>
              <Link to="/contacto">
                <button className="bg-gray-100 hover:bg-gray-300 focus:bg-gray-400 active:bg-gray-500 text-gray-900 px-6 py-3 rounded-md font-medium w-full md:w-auto">
                  Contáctanos
                </button>
              </Link>
            </motion.div>
          </motion.div>
        </div>
        <motion.button
          onClick={handlePrevSlide}
          aria-label="Slide anterior"
          whileHover={{ scale: 1.2 }}
          whileTap={{ scale: 0.95 }}
          className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-transparent text-white p-4 rounded-full shadow-lg transition-transform duration-200 ease-in-out flex items-center justify-center hidden md:block"
        >
          &#8249;
        </motion.button>
        <motion.button
          onClick={handleNextSlide}
          aria-label="Siguiente slide"
          whileHover={{ scale: 1.2 }}
          whileTap={{ scale: 0.95 }}
          className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-transparent text-white p-4 rounded-full shadow-lg transition-transform duration-200 ease-in-out flex items-center justify-center hidden md:block"
        >
          &#8250;
        </motion.button>
      </section>

<section className="py-16 bg-gray-50">
      <div className="container mx-auto px-6 md:px-12">
        <motion.div
          className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
          transition={{ duration: 0.6 }}
        >
          {/* Card 1 */}
          <motion.div
            className="bg-gray-800 text-white text-center p-8 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300"
            whileHover={{ scale: 1.05 }}
          >
            <div className="flex justify-center items-center bg-blue-200 h-16 w-16 rounded-full mx-auto mb-6">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8 text-blue-700"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M13 10V3L4 14h7v7l9-11h-7z"
                />
              </svg>
            </div>
            <h3 className="text-xl font-bold mb-4">Innovación</h3>
            <p className="text-gray-300">
              En Cohuin, adoptamos tecnologías avanzadas para ofrecer soluciones
              innovadoras.
            </p>
          </motion.div>

          {/* Card 2 */}
          <motion.div
            className="bg-blue-500 text-white text-center p-8 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300"
            whileHover={{ scale: 1.05 }}
          >
            <div className="flex justify-center items-center bg-white h-16 w-16 rounded-full mx-auto mb-6">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8 text-blue-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-1-13h2v6h-2zm0 8h2v2h-2z"
                />
              </svg>
            </div>
            <h3 className="text-xl font-bold mb-4">Calidad</h3>
            <p>
              Nuestra prioridad es ofrecer productos de la más alta calidad,
              garantizando durabilidad.
            </p>
          </motion.div>

          {/* Card 3 */}
          <motion.div
            className="bg-gray-800 text-white text-center p-8 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300"
            whileHover={{ scale: 1.05 }}
          >
            <div className="flex justify-center items-center bg-blue-200 h-16 w-16 rounded-full mx-auto mb-6">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8 text-blue-700"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 11c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6C6.48 5 2 9.48 2 15s4.48 10 10 10 10-4.48 10-10S17.52 5 12 5zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-1-13h2v6h-2zm0 8h2v2h-2z"
                />
              </svg>
            </div>
            <h3 className="text-xl font-bold mb-4">Confianza</h3>
            <p className="text-gray-300">
              Construimos relaciones de confianza con nuestros clientes
              asegurando resultados.
            </p>
          </motion.div>
        </motion.div>
      </div>
    </section>
      

      
{/* Separador de Secciones */}
<hr className="border-gray-300 my-10" />
{/* Sección Unificada: ¿Por qué Elegirnos? y Carrusel */}
<section className="py-20 bg-gray-50">
        <div className="container mx-auto text-center px-6">
          <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4 text-gray-800">
            ¿Por qué elegirnos?
          </h2>
          <div className="flex justify-center">
            <div className="border-t-4 border-blue-500 w-16 md:w-20 lg:w-24 mb-6"></div>
          </div>
          <p className="text-gray-600 max-w-lg md:max-w-2xl lg:max-w-4xl mx-auto mb-8 md:mb-12 text-sm sm:text-base lg:text-lg">
            "Ofrecemos soluciones integrales adaptadas a tus necesidades, garantizando calidad, confianza y resultados duraderos."
          </p>
        </div>

        <div className="container mx-auto px-6 relative">
        <button
  className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-blue-500 text-white p-3 rounded-full shadow-lg focus:outline-none md:hidden"
  onClick={handlePrevSlide}
>
  &#8249;
</button>
          <div
            className="flex gap-6 overflow-x-auto snap-x snap-mandatory pb-4 scrollbar-hide scroll-smooth"
            ref={carouselRef}
          >
            {cards.map((item, index) => (
              <div
                key={index}
                className="snap-center flex-shrink-0 w-64 md:w-[280px] lg:w-[320px] xl:w-[360px] bg-white shadow-lg rounded-lg p-6 md:p-8 mx-auto transform hover:scale-105 transition-transform duration-300"
              >
                <div className="flex justify-center items-center bg-green-100 h-14 w-14 md:h-16 md:w-16 lg:h-20 lg:w-20 rounded-full mx-auto mb-4 md:mb-6">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 md:h-8 md:w-8 lg:h-10 lg:w-10 text-green-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M13 10V3L4 14h7v7l9-11h-7z"
                    />
                  </svg>
                </div>
                <h3 className="text-lg md:text-xl lg:text-2xl font-bold text-gray-800 text-center mb-4">
                  {item.title}
                </h3>
                <p className="text-gray-600 text-center leading-relaxed mb-6 text-sm md:text-base lg:text-lg">
                  {item.description}
                </p>
                <div className="flex justify-center">
                  <Link
                    to={item.link}
                    className="bg-blue-500 text-white text-sm md:text-base lg:text-lg px-4 py-2 md:px-6 md:py-3 rounded-md hover:bg-blue-600 transition"
                  >
                    {item.buttonLabel}
                  </Link>
                </div>
              </div>
            ))}
          </div>
          <button
  className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-blue-500 text-white p-3 rounded-full shadow-lg focus:outline-none md:hidden"
  onClick={handleNextSlide}
>
  &#8250;
</button>
        </div>
      </section>




    <section className="py-20 bg-gradient-to-b from-gray-200 to-white">
  <div className="container mx-auto px-6 md:px-12 grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
    {/* Text Section */}
    <motion.div
      className="space-y-6 text-center md:text-left"
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.6 }}
    >
      <span className="inline-block text-sm uppercase font-semibold text-blue-600 bg-blue-100 px-4 py-2 rounded-md shadow-md">
        Nuestros Servicios
      </span>
      <h2 className="text-3xl md:text-4xl font-extrabold text-gray-800 leading-tight">
        Equipamiento Profesional <br /> para Cada Necesidad
      </h2>
      <p className="text-gray-600 leading-relaxed">
        Desde equipos de protección personal hasta señalización vial y minera,
        ofrecemos productos de alta calidad que cumplen con los estándares más
        exigentes de la industria.
      </p>
      <div className="flex flex-col sm:flex-row justify-center md:justify-start gap-4">
        <Link
          to="/contacto"
          className="bg-green-600 hover:bg-green-700 text-white px-6 py-3 rounded-md shadow-lg font-medium transition-all"
        >
          Contáctanos
        </Link>
        <Link
          to="/nosotros"
          className="bg-gray-200 hover:bg-gray-300 text-gray-700 px-6 py-3 rounded-md shadow font-medium transition-all"
        >
          Más Información
        </Link>
      </div>
    </motion.div>

    {/* Icon Grid Section */}
    <motion.div
      className="grid grid-cols-2 gap-6"
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      variants={{
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0 },
      }}
      transition={{ duration: 0.6 }}
    >
      {/* EPP */}
      <Link to="/productos/Equipos%20de%20Protección%20Personal%20(EPP)">
        <motion.div
          className="bg-gradient-to-br from-green-500 to-green-700 flex justify-center items-center h-32 md:h-40 w-full rounded-xl shadow-lg"
          whileHover={{ scale: 1.05 }}
        >
          <h3 className="text-white text-base md:text-xl font-bold">EPP</h3>
        </motion.div>
      </Link>
      {/* Señalización */}
      <Link to="/productos/Señalización">
        <motion.div
          className="bg-gradient-to-br from-blue-500 to-blue-700 flex justify-center items-center h-32 md:h-40 w-full rounded-xl shadow-lg"
          whileHover={{ scale: 1.05 }}
        >
          <h3 className="text-white text-base md:text-xl font-bold">Señalización</h3>
        </motion.div>
      </Link>
      {/* Ferretería */}
      <Link to="/productos/Ferretería">
        <motion.div
          className="bg-gradient-to-br from-purple-500 to-purple-700 flex justify-center items-center h-32 md:h-40 w-full rounded-xl shadow-lg"
          whileHover={{ scale: 1.05 }}
        >
          <h3 className="text-white text-base md:text-xl font-bold">Ferretería</h3>
        </motion.div>
      </Link>
      {/* Seguridad */}
      <Link to="/productos/Servicios">
        <motion.div
          className="bg-gradient-to-br from-yellow-500 to-yellow-700 flex justify-center items-center h-32 md:h-40 w-full rounded-xl shadow-lg"
          whileHover={{ scale: 1.05 }}
        >
          <h3 className="text-white text-base md:text-xl font-bold">Seguridad</h3>
        </motion.div>
      </Link>
    </motion.div>
  </div>
</section>

          {/* Sección de Testimonios */}
      <section className="py-16 bg-gray-100">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl md:text-4xl font-bold text-center text-blue-600 mb-12">
            
          </h2>
          <TestimonialCarousel />
        </div>
      </section>
    </div>
  );
};

export default HomePage;
