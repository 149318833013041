import React from 'react';
import HeroSection from '../components/ui/HeroSection';
import AboutSection from './sections/NosotrosSections/AboutSection';
import MissionVisionSection from './sections/NosotrosSections/MissionVisionSection';
import TimelineSection from './sections/NosotrosSections/TimeLineSection';
import ValuesSection from './sections/NosotrosSections/ValuesSection';
import SEO from '../components/SEO';

export default function AboutPage() {
  return (
    <div className="min-h-screen bg-white" >
      <SEO
        title="Nosotros"
        description="Conoce más sobre Corporación Cohuin SAC, nuestra misión, visión y trayectoria."
        url="https://cohuin.com/nosotros"
        image="https://www.tusitio.com/assets/images/about-image.jpg"
        imageAlt="Sobre Nosotros - Corporación Cohuin SAC"
      />
      {/* Hero Section */}
      <HeroSection title="Nosotros"  />
      <div className="mx-3">

      {/* About Section */}
      <AboutSection />

      {/* Mission & Vision Section */}
      <MissionVisionSection />

      {/* Timeline Section */}
      <TimelineSection />

      {/* Values Section */}
      <ValuesSection />
      </div>
    </div>
  );
}