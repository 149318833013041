import React from "react";
import { Button } from "../../../components/ui/Button";

interface CotizacionFormProps {
  productName: string;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  agregarMensajeAdicional: boolean; // Recibir el estado como prop
  setAgregarMensajeAdicional: (valor: boolean) => void; // Recibir el setter como prop
}

const CotizacionForm: React.FC<CotizacionFormProps> = ({
  productName,
  handleSubmit,
  agregarMensajeAdicional,
  setAgregarMensajeAdicional,
}) => {
  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {/* Opciones de Mensaje Predeterminado */}
      <div className="grid grid-cols-1 md:grid-cols-3 items-center gap-4">
        <label htmlFor="mensajePredeterminado" className="text-sm font-medium text-gray-700">
          Mensaje Predeterminado:
        </label>
        <select
          id="mensajePredeterminado"
          name="mensajePredeterminado"
          className="mt-1 md:col-span-2 w-full border-gray-300 rounded-xl shadow-sm p-2 focus:ring-blue-500 focus:border-blue-500 transition"
          required
        >
          <option value={`Quisiera cotizar el producto ${productName}`}>
            Quisiera cotizar el producto {productName}
          </option>
          <option value={`Necesito más información sobre el producto ${productName}`}>
            Necesito más información sobre el producto {productName}
          </option>
          <option value={`Quiero saber el precio del producto ${productName}`}>
            Quiero saber el precio del producto {productName}
          </option>
        </select>
      </div>

      {/* Checkbox para agregar Mensaje Adicional */}
      <div className="flex items-center space-x-2">
        <input
          type="checkbox"
          id="agregarMensajeAdicional"
          name="agregarMensajeAdicional"
          className="h-4 w-4 text-blue-600 border-gray-300 rounded-xl focus:ring-blue-500"
          checked={agregarMensajeAdicional}
          onChange={(e) => setAgregarMensajeAdicional(e.target.checked)}
        />
        <label htmlFor="agregarMensajeAdicional" className="text-sm font-medium text-gray-700">
          Agregar Mensaje Adicional
        </label>
      </div>

      {/* Campo Mensaje Adicional (condicional) */}
      {agregarMensajeAdicional && (
        <div className="grid grid-cols-1 md:grid-cols-3 items-start gap-4">
          <label htmlFor="mensaje" className="text-sm font-medium text-gray-700">
            Mensaje Adicional:
          </label>
          <textarea
            id="mensaje"
            name="mensaje"
            rows={4}
            className="mt-1 md:col-span-2 block w-full border-gray-300 rounded-md shadow-sm p-2 focus:ring-blue-500 focus:border-blue-500 transition"
            placeholder="Escribe algún mensaje adicional (opcional)"
          ></textarea>
        </div>
      )}

      {/* Botón de Envío y Nota */}
      <div>
        <Button
          type="submit"
          className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition duration-300 ease-in-out shadow-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          Cotizar
        </Button>
        <p className="mt-2 text-xs text-gray-500">
          * La cotización se realizará mediante WhatsApp.
        </p>
      </div>
    </form>
  );
};

export default CotizacionForm;