import { collection, getDocs, query, orderBy, where } from 'firebase/firestore';
import { db } from '../firebase';
import { Product } from '../types/Product';

export const ProductService = {
  fetchAllProducts: async (): Promise<Product[]> => {
    const productsCollection = collection(db, "products");
    const q = query(productsCollection, orderBy("nombre"));
    const querySnapshot = await getDocs(q);
    const products: Product[] = querySnapshot.docs.map(doc => {
      const data = doc.data();
      return {
        name: data.nombre || "",
        model: data.codigo || "",
        category: data.categoria || "",
        subcategory: data.subcategoria || "",
        image: data.foto_url || "",
        description: data.especificaciones || "",
      };
    });
    return products;
  },

  fetchProductByModel: async (model: string): Promise<Product | null> => {
    const productsCollection = collection(db, "products");
    const q = query(productsCollection, where("codigo", "==", model));
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      const data = querySnapshot.docs[0].data();
      return {
        name: data.nombre || "",
        model: data.codigo || "",
        category: data.categoria || "",
        subcategory: data.subcategoria || "",
        image: data.foto_url || "",
        description: data.especificaciones || "",
      };
    }
    return null;
  }
};