import React, { useState, useEffect } from 'react';
import { collection, getDocs, deleteDoc, doc, setDoc } from 'firebase/firestore';
import { db, auth } from '../../firebase';
import ProductsCrudSection from './sections/ProductsCrudSection';
import Swal from 'sweetalert2';
import { FaEdit, FaTrash, FaPlus, FaArrowLeft, FaArrowRight, FaSignOutAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom'; 
import { signOut } from 'firebase/auth'; 
//import 'animate.css';

const ProductsPage = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [productToEdit, setProductToEdit] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 20;
  const navigate = useNavigate(); // Inicializa useNavigate
  const fetchProducts = async () => {
    try {
      setLoading(true);
      const querySnapshot = await getDocs(collection(db, 'products'));
      const productsList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setProducts(productsList);
      setLoading(false);
    } catch (err) {
      console.error('Error al obtener productos:', err);
      Swal.fire('Error', 'No se pudieron cargar los productos', 'error');
      setLoading(false);
    }
  };
  const handleLogout = async () => {
    try {
      await signOut(auth);
      Swal.fire('Desconectado', 'Has cerrado sesión correctamente.', 'success');
      navigate('/admin/login');
    } catch (error) {
      console.error('Error al cerrar sesión:', error);
      Swal.fire('Error', 'No se pudo cerrar sesión.', 'error');
    }
  };
  useEffect(() => {
    fetchProducts();
  }, []);

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = products.slice(indexOfFirstProduct, indexOfLastProduct);
  const totalPages = Math.ceil(products.length / productsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleEdit = (product) => {
    setProductToEdit(product); // Pasa el producto actual al modal
    setIsModalOpen(true);
  };

  const handleDelete = async (id) => {
    const confirmation = await Swal.fire({
      title: '¿Estás seguro?',
      text: 'Esta acción eliminará el producto permanentemente',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar',
    });

    if (confirmation.isConfirmed) {
      try {
        await deleteDoc(doc(db, 'products', id));
        Swal.fire('¡Eliminado!', 'El producto ha sido eliminado.', 'success');
        fetchProducts();
      } catch (err) {
        console.error('Error al eliminar producto:', err);
        Swal.fire('Error', 'No se pudo eliminar el producto', 'error');
      }
    }
  };

  const handleSaveProduct = async (product) => {
    try {
      if (product.id) {
        // Si el producto tiene ID, actualiza el documento existente
        const productRef = doc(db, 'products', product.id);
        await setDoc(productRef, product, { merge: true }); // `merge: true` actualiza sin sobrescribir todo
        Swal.fire('¡Éxito!', 'El producto ha sido actualizado correctamente.', 'success');
      } else {
        // Si no tiene ID, crea un nuevo documento
        const newProductRef = doc(collection(db, 'products'));
        await setDoc(newProductRef, { ...product, id: newProductRef.id });
        Swal.fire('¡Éxito!', 'El producto ha sido agregado correctamente.', 'success');
      }
      fetchProducts();
      handleModalClose();
    } catch (err) {
      console.error('Error al guardar producto:', err);
      Swal.fire('Error', 'No se pudo guardar el producto', 'error');
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setProductToEdit(null);
  };

  return (
    <div className="p-8 min-h-screen bg-gray-900 animate__animated animate__fadeIn">
      {/* Botón de Cerrar Sesión */}
      <div className="absolute top-4 right-4">
        <button
          onClick={handleLogout}
          className="flex items-center gap-2 bg-red-600 text-white font-bold py-2 px-4 rounded hover:bg-red-700"
        >
          <FaSignOutAlt />
          Cerrar Sesión
        </button>
      </div>
      <h1 className="text-3xl font-bold text-white mb-12 text-center">
        Gestión de Productos
      </h1>
      <button
        className="mb-4 flex items-center gap-2 bg-green-600 text-white font-bold py-2 px-4 rounded hover:bg-green-700"
        onClick={() => setIsModalOpen(true)}
      >
        <FaPlus />
        Agregar Producto
      </button>
      {loading ? (
        <p className="text-white animate__animated animate__flash">
          Cargando productos...
        </p>
      ) : (
        <>
          <table className="min-w-full text-white border border-gray-700 bg-gray-800 rounded-lg shadow-md animate__animated animate__fadeInUp">
            <thead className="bg-gray-900">
              <tr>
                <th className="border px-4 py-2 text-center">Código</th>
                <th className="border px-4 py-2 text-center">Nombre</th>
                <th className="border px-4 py-2 text-center">Categoría</th>
                <th className="border px-4 py-2 text-center">Subcategoría</th>
                <th className="border px-4 py-2 text-center">Especificaciones</th>
                <th className="border px-4 py-2 text-center">Foto</th>
                <th className="border px-4 py-2 text-center">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {currentProducts.map((product) => (
                <tr key={product.id} className="hover:bg-gray-700">
                  <td className="border px-4 py-2">{product.codigo}</td>
                  <td className="border px-4 py-2">{product.nombre}</td>
                  <td className="border px-4 py-2">{product.categoria}</td>
                  <td className="border px-4 py-2">{product.subcategoria}</td>
                  <td className="border px-4 py-2">{product.especificaciones}</td>
                  <td className="border px-4 py-2">
                    {product.foto_url ? (
                      <img
                        src={product.foto_url}
                        alt={product.nombre}
                        className="object-cover w-16 h-16 rounded-md"
                      />
                    ) : (
                      <span>No disponible</span>
                    )}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    <div className="flex justify-center items-center space-x-4">
                      <button
                        className="flex items-center gap-1 bg-blue-600 text-white font-bold py-1 px-2 rounded hover:bg-blue-700"
                        onClick={() => handleEdit(product)}
                      >
                        <FaEdit />
                        Editar
                      </button>
                      <button
                        className="flex items-center gap-1 bg-red-600 text-white font-bold py-1 px-2 rounded hover:bg-red-700"
                        onClick={() => handleDelete(product.id)}
                      >
                        <FaTrash />
                        Eliminar
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="flex justify-center items-center mt-6 text-white space-x-2 animate__animated animate__fadeInUp">
            <button
              className={`px-4 py-2 bg-gray-700 rounded flex items-center gap-2 ${
                currentPage === 1 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-600'
              }`}
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <FaArrowLeft />
              Anterior
            </button>

            {[...Array(totalPages)].map((_, index) => (
              <button
                key={index}
                className={`px-4 py-2 rounded ${
                  currentPage === index + 1
                    ? 'bg-blue-600'
                    : 'bg-gray-700 hover:bg-gray-600'
                }`}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </button>
            ))}

            <button
              className={`px-4 py-2 bg-gray-700 rounded flex items-center gap-2 ${
                currentPage === totalPages
                  ? 'opacity-50 cursor-not-allowed'
                  : 'hover:bg-gray-600'
              }`}
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Siguiente
              <FaArrowRight />
            </button>
          </div>

          <p className="text-center mt-4 text-white">
            Página {currentPage} de {totalPages}
          </p>
        </>
      )}
      {isModalOpen && (
        <ProductsCrudSection
          onClose={handleModalClose}
          fetchProducts={fetchProducts}
          editingProduct={productToEdit}
          onSave={handleSaveProduct} // Pasa el handler para guardar
        />
      )}
    </div>
  );
};

export default ProductsPage;
