// src/data/subcategories/eppsSubcategories.ts
import Guantes from '../../assets/images/GuantesEPPS.webp';
import Placeholder from '../../assets/images/Placeholder.svg';
import { Shield, Eye, HardHat, UserCheck } from 'lucide-react';

const eppsSubcategories = [
  {
    id: "Cascos de Seguridad",
    title: "Cascos de Seguridad",
    description: "Protección craneal certificada",
    icon: HardHat,
    image: Placeholder,
  },
  {
    id: "Orejeras de Seguridad",
    title: "Orejeras de Seguridad",
    description: "Protección auditiva de alta calidad",
    icon: UserCheck,
    image: Placeholder,
  },
  {
    id: "Guantes de Seguridad",
    title: "Guantes de Seguridad",
    description: "Protección certificada para las manos",
    icon: Shield,
    image: Guantes,
  },
  {
    id: "Lentes de Seguridad",
    title: "Lentes de Seguridad",
    description: "Protección visual de alta calidad",
    icon: Eye,
    image: Placeholder,
  },
  {
    id: "Protección Minera",
    title: "Protección Minera",
    description: "Equipamiento completo de seguridad minera",
    icon: UserCheck,
    image: Placeholder,
  },
  {
    id: "Zapatos de Seguridad",
    title: "Zapatos de Seguridad",
    description: "Protección certificada para los pies",
    icon: UserCheck,
    image: Placeholder,
  },
  {
    id: "Protección Respiratoria",
    title: "Protección Respiratoria",
    description: "Equipamiento de seguridad respiratoria",
    icon: UserCheck,
    image: Placeholder,
  },
  {
    id: "Protección Corporal",
    title: "Protección Corporal",
    description: "Equipamiento completo de seguridad",
    icon: UserCheck,
    image: Placeholder,
  },
  {
    id: "Bloqueo y Etiquetado",
    title: "Bloqueo y Etiquetado",
    description: "Sistemas de bloqueo y etiquetado de seguridad",
    icon: UserCheck,
    image: Placeholder,
  }
];

export default eppsSubcategories;