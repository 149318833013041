import ServiciosImage from '../assets/images/servicios.jpg'
import { FaTools, FaHardHat, FaBuilding } from 'react-icons/fa'

export interface Service {
  title: string
  description: string
  image: string
  icon: React.ComponentType<any>
}

const services: Service[] = [
  {
    title: 'Servicios de instalaciones general',
    description: 'Corporación Cohuin SAC ofrece una amplia gama de servicios de instalación en general, cubriendo una variedad de necesidades industriales y mineras. Esto incluye la instalación de equipos, sistemas eléctricos, hidráulicos y mecánicos, entre otros. La empresa garantiza un servicio profesional, con plazos de ejecución ajustados a las necesidades del cliente, y con un enfoque en la seguridad y calidad de cada instalación.',
    image: ServiciosImage,
    icon: FaTools,
  },
  {
    title: 'Ejecución de proyectos mineros y mantenimiento en general',
    description: 'Corporación Cohuin SAC se especializa en la ejecución integral de proyectos mineros, desde la planificación hasta la implementación. Además, ofrece servicios de mantenimiento preventivo y correctivo para garantizar el óptimo funcionamiento de los equipos y sistemas en las operaciones mineras. La empresa tiene experiencia en el sector minero, proporcionando soluciones eficientes y de calidad para mejorar la productividad y seguridad de las operaciones.',
    image: ServiciosImage,
    icon: FaHardHat,
  },
  {
    title: 'Diseño, fabricación y montajes de estructuras metálicas',
    description: 'Corporación Cohuin SAC se dedica al diseño, fabricación y montaje de estructuras metálicas a medida, adaptadas a las necesidades específicas de sus clientes. Estas estructuras se utilizan en diversos sectores, incluyendo minería e industria, y son fabricadas con materiales de alta calidad para garantizar su resistencia, durabilidad y funcionalidad. Su equipo de expertos asegura que cada proyecto cumpla con los más altos estándares de seguridad y eficiencia.',
    image: ServiciosImage,
    icon: FaBuilding,
  },
]

export default services