const seoConfig = {
    baseURL: "https://cohuin.com", 
    title: "Corporación Cohuin SAC",
    description: "Proveedor integral especializado en productos de ferretería, señalizaciones viales y mineras, y equipos de protección personal (EPP).",
    image: {
      url: "https://firebasestorage.googleapis.com/v0/b/cohuin-sac.firebasestorage.app/o/SEO%2Fsenalizacion.jpg?alt=media&token=0ea423df-5a83-4bc6-9167-551056ec9a6e", // URL de la imagen por defecto
      alt: "Corporación Cohuin SAC", // Texto alternativo de la imagen por defecto
    },
    twitter: "", // Reemplaza con tu handle de Twitter
  };
  
  export default seoConfig;