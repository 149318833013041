import { Badge } from "../../../components/ui/Badge";
import { Button } from "../../../components/ui/Button";

interface ProductInfoProps {
  name: string;
  code: string;
  category: string;
}

export default function ProductInfo({ name, code, category  }: ProductInfoProps) {
  return (
    <div className="space-y-8">
      <div>
        <Badge className="bg-blue-100 text-blue-700 hover:bg-blue-200 mb-4">
          {category}  
        </Badge>
        <h1 className="sm:text-[40px] text-3xl font-bold text-gray-900 mb-2 py-4">{name}</h1>
        <p className="sm:text-xl text-lg text-gray-500">Código: {code}</p>
        <div className="mt-4">
        </div>
      </div>

    </div>
  );
}