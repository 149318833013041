import Placeholder from '../../assets/images/Placeholder.svg';
import { Paintbrush, Wrench, Zap, Anchor, Hammer, PaintRoller, Droplet } from 'lucide-react';

const ferreteriaSubcategories = [
  {
    id: "Materiales de Construcción",
    title: "Materiales de Construcción",
    description: "Señalización vial de alta calidad",
    icon: Paintbrush,
    image: Placeholder,
  },
  {
    id: "Herramientas Eléctricas",
    title: "Herramientas Eléctricas",
    description: "Señalización minera de alta calidad",
    icon: Wrench,
    image: Placeholder,
  },
  {
    id: "Electricidad e Iluminación",
    title: "Electricidad e Iluminación",
    description: "Señalización industrial de alta calidad",
    icon: Zap,
    image: Placeholder,
  },
  {
    id: "Fijaciones y Sujeciones",
    title: "Fijaciones y Sujeciones",
    description: "Señalización vial y minera de alta calidad",
    icon: Anchor,
    image: Placeholder,
  },
  {
    id: "Herramientas Manuales",
    title: "Herramientas Manuales",
    description: "Señalización vial de alta calidad",
    icon: Hammer,
    image: Placeholder,
  },
  {
    id: "Pinturas y Acabados",
    title: "Pinturas y Acabados",
    description: "Señalización vial de alta calidad",
    icon: PaintRoller,
    image: Placeholder,
  },
  {
    id: "Plomería y Accesorios",
    title: "Plomería y Accesorios",
    description: "Señalización vial de alta calidad",
    icon: Droplet,
    image: Placeholder,
  }
];

export default ferreteriaSubcategories;