'use client'

import React, { useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import ServiceCard from '../components/ui/ServiceCard'
import ServiceModal from '../components/ui/ServiceModal'
import HeroSection from '../components/ui/HeroSection'
import { Badge } from '../components/ui/Badge'
import ValoresImage from '../assets/images/servicios-1.jpg'
import { motion } from 'framer-motion';
import services from '../data/services';
import SEO from '../components/SEO';


// Componente Principal
export default function ServicesPage() {
  const [selectedService, setSelectedService] = useState<number | null>(null)

  return (
    <div className="min-h-screen mx-auto bg-white text-black">
      <SEO
        title="Servicios"
        description="Conoce más sobre los servicios que ofrecemos en Corporación Cohuin SAC."
        url="https://cohuin.com/productos/Servicios"
        image="https://www.tusitio.com/assets/images/servicios-1.jpg"
        imageAlt="Nuestros Servicios - Corporación Cohuin SAC"
      />
            <HeroSection title="Servicios de Cohuin" />
      <div className="max-w-4xl mx-auto bg-white text-center text-black mb-32">

    <div className="text-center mb-10 mt-16">
      <Badge className="mb-4 bg-gradient-to-r from-[#8BA888] to-blue-600 text-white">
              Nuestros Servicios
            </Badge>
        <h2 className="text-4xl sm:text-7xl font-bold text-center text-gray-800">
          Calidad y Excelencia.
        </h2>
        </div>

        {/* Tarjeta Descriptiva */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="flex flex-col md:flex-row items-center bg-gray-100 rounded-[30px] shadow-lg mb-12 overflow-hidden mx-3"
        >
          {/* Texto a la Izquierda */}
          <div className="md:w-1/2 p-8">
            <h3 className="sm:text-3xl text-2xl font-semibold mb-4 text-blue-600">Nuestros Servicios</h3>
            <p className="text-gray-700 text-sm sm:text-base">
              Ofrecemos una amplia gama de servicios diseñados para satisfacer las necesidades específicas de nuestros clientes. Desde instalaciones generales hasta proyectos mineros y fabricación de estructuras metálicas, nuestro equipo está equipado para entregar resultados excepcionales.
            </p>
          </div>
          {/* Imagen a la Derecha */}
          <div className="md:w-1/2">
            <img
              src={ValoresImage}
              alt="Nuestros Servicios"
              className="object-cover w-full h-full"
            />
          </div>
        </motion.div>

        {/* Cuadrícula de ServiceCards */}
        <div className="grid grid-cols-1 md:grid-cols-1 gap-8 mx-3">
          {services.map((service, index) => (
            <ServiceCard
              key={index}
              service={service}
              index={index}
              onMoreInfo={() => setSelectedService(index)}
            />
          ))}
        </div>
      </div>

      {/* Modal */}
      <AnimatePresence>
        {selectedService !== null && (
          <ServiceModal
            service={services[selectedService]}
            onClose={() => setSelectedService(null)}
          />
        )}
      </AnimatePresence>
    </div>
  )
}