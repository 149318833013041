import { Badge } from "../../../components/ui/Badge";
import React, { useState, MouseEvent } from "react";

interface ImageGalleryProps {
  images: string[];
  productName: string;
  handleImageError: (event: React.SyntheticEvent<HTMLImageElement, Event>) => void;
}

export default function ImageGallery({
  images,
  productName,
  handleImageError,
}: ImageGalleryProps) {
  const [selectedImage, setSelectedImage] = useState(0);
  const [transformOrigin, setTransformOrigin] = useState<string>("center");

  const handleMouseMove = (e: MouseEvent<HTMLDivElement>) => {
    const { left, top, width, height } = e.currentTarget.getBoundingClientRect();
    const x = ((e.clientX - left) / width) * 100;
    const y = ((e.clientY - top) / height) * 100;
    setTransformOrigin(`${x}% ${y}%`);
  };

  const handleMouseLeave = () => {
    setTransformOrigin("center");
  };

  return (
    <div className="space-y-4">
      {/* Imagen Principal con Efecto de Zoom Interno */}
      <div
        className="
          relative bg-white rounded-[30px] overflow-hidden
          w-full
          max-w-2xl lg:max-w-3xl md:max-w-96 sm:max-w-full
          mx-auto
        "
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      >
        <img
          src={images[selectedImage]}
          alt={productName}
          className="
            object-contain
            p-4 sm:p-2 md:p-4 lg:p-8
            w-full h-full
            transition-transform duration-300
            transform hover:scale-125
          "
          style={{ transformOrigin }}
          onError={handleImageError}
        />
      </div>
    </div>
  );
}