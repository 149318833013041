'use client'

import React from 'react'
import { motion } from 'framer-motion'
import { X } from 'lucide-react'
import { Service } from '../../data/services'


interface ServiceModalProps {
  service: Service
  onClose: () => void
}

const ServiceModal: React.FC<ServiceModalProps> = ({ service, onClose }) => {
  const IconComponent = service.icon // Obtiene el componente del ícono

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center p-4 z-50"
      onClick={onClose}
    >
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
        className="bg-white rounded-[30px] shadow-xl max-w-2xl w-full relative overflow-hidden"
        onClick={(e) => e.stopPropagation()}
      >
        {/* Botón "X" para cerrar */}
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-black"
          aria-label="Cerrar modal"
        >
          <X size={24} />
        </button>
         {/* Contenido */}
        <div className="p-6 sm:m-10 m-2">
          {/* Ícono */}
          <IconComponent size={50} className="text-blue-600 mb-8" />
          <div className="flex items-center mb-4">
            {/* Ícono grande */}
            <h2 className="text-xl font-bold mb-0">{service.title}</h2>
          </div>
          <p className="text-gray-700 sm:text-base text-sm">{service.description}</p>
        </div>
      </motion.div>
    </motion.div>
  )
}

export default ServiceModal