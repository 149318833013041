interface PhoneNumber {
  name: string;
  number: string;
  message: string;
  label: string;
}

const phoneNumbers: PhoneNumber[] = [
  { name: 'Monica Samar', label: 'Ventas - Lima - Lima', number: '+51950284447', message: 'Hola, necesito información sobre las ventas en Lima.' },
  { name: 'Katerin Bonillaaa', label: 'Ventas - Lima - Lima', number: '+51932723864', message: 'Hola, necesito información sobre las ventas en Lima.' },
  { name: 'Jhonatan Villavicencio', label: 'Ventas - Grau - Lima', number: '+51944888761', message: 'Hola, necesito información sobre las ventas en Grau.' },
  { name: 'Lisbeth Giron', label: 'Ventas - Oyon - Oyon', number: '+51974657332', message: 'Hola, necesito información sobre las ventas en Oyon.' },
];

export default phoneNumbers;
