import { Badge } from "../../../components/ui/Badge";
import { Card, CardContent } from "../../../components/ui/Card";
import { Link } from 'react-router-dom';
import categories from '../../../data/categories'; 
import CatalogDownloadButton from '../../../components/ui/CatalogDownloadButton';
// Importa las imágenes
import Placeholder from '../../../assets/images/Placeholder.svg';
import '../../../styles/app.css'
export default function CategoriesGrid() {
  const handleImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.src = Placeholder; // Establece el placeholder
  };
  const isDownloadAttributeSupported = () => {
    const a = document.createElement('a');
    return typeof a.download !== 'undefined';
  };

  const handleDownload = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, catalog: string) => {
    // Detener la propagación del evento para evitar que active el <Link>
    e.stopPropagation();

    if (isDownloadAttributeSupported()) {
      // Si el atributo download es soportado, permitir que el navegador lo maneje
      // No es necesario hacer nada más aquí
    } else {
      // Si no es soportado, abrir el PDF en una nueva pestaña
      window.open(catalog, '_blank');
    }
  };

    // Función para determinar la ruta del Link
    const getLinkPath = (categoryId: string) => {
      return categoryId === 'servicios' ? '/servicios' : `/productos/${categoryId}`;
    };

  return (
    <section className="py-16 bg-gradient-to-b from-white to-gray-50">
      <div className="container max-w-6xl mx-auto ">
        <div className="grid mx-3 md:grid-cols-2 gap-8">
          {categories.map((category) => (
            <Link key={category.id} to={getLinkPath(category.id)}> 
              <Card className="group cursor-pointer border-none shadow-lg hover:shadow-xl transition-all duration-300 overflow-hidden">
                <CardContent className="p-0">
                  <div className="relative h-64">
                    <div className={`absolute inset-0 bg-gradient-to-r ${category.gradient} opacity-90 group-hover:opacity-100 transition-opacity`} />
                    <img
                      src={category.image}
                      alt={category.title}
                      className="object-cover w-full h-full"
                      onError={handleImageError}
                    />
                    <div className="absolute inset-0 p-8 flex flex-col justify-between">
                      <div className="flex items-start justify-between">
                        <Badge className="bg-white/90 text-[#8BA888]">
                        {category.id === 'servicios' ? 'Ver Servicios' : 'Ver Productos'}                        </Badge>
                        <category.icon className="w-8 h-8 text-white" />
                      </div>
                      <div>
                        <h2 className="text-2xl font-bold text-white mb-2">
                          {category.title}
                        </h2>
                        <p className="text-white/90">
                          {category.description}
                        </p>
                        {/* Botón para descargar el catálogo */}
                        <CatalogDownloadButton 
                          catalog={category.catalog}
                          handleDownload={handleDownload}
                        />
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
}