import { Outlet, useLocation } from 'react-router-dom';
import { Footer } from './Footer';
import Header from './Header';

export function Layout() {
  const location = useLocation();
  const hideHeader = location.pathname === '/products'; // Ocultar Header si la ruta es "/productos"

  return (
    <>
      {!hideHeader && <Header />}
      <main className={`pt-${hideHeader ? '0' : '32'} text-black`}> {/* Modificado: Ajuste de pt dinámico */}
        <Outlet />
      </main>
      <Footer />
    </>
  );
}
