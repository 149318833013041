import React, { JSX } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { Layout } from './components/Layout'
import { QueryClient, QueryClientProvider } from 'react-query';
import Inicio from './pages/Inicio';
import Header from './components/Header'; 
import WhatsappFloat from './components/WhatsAppFloatButton';
import { ProductsProvider } from './contexts/ProductsContext';
import ServiciosPage from './pages/Servicios';
import ContactPage from './pages/ContactPage';
import Nosotros from './pages/Nosotros';
import NuestrosClientes from './pages/NuestrosClientes';
import Productos from './pages/Productos';
import Categoria from './pages/Categoria';
import ProductoDetalle from './pages/ProductoDetalle';
import AllProducts from './pages/AllProducts';
import FilteredProductsPage from './pages/FilteredProducts';
import NotFound from './pages/NotFound';
// ADMIN PAGES
import Login from './admin/pages/Login';
import ProductsPage from "./admin/pages/ProductsPage";
import ProtectedRoute from './admin/pages/components/ProtectedRoute';
const queryClient = new QueryClient();

function App(): JSX.Element {
return (
  <QueryClientProvider client={queryClient}>
    <Router>
      <AppContent />
    </Router>
  </QueryClientProvider>
);
}

function AppContent(): JSX.Element {
  const location = useLocation();

  const isAdminRoute = location.pathname.startsWith('/admin');

  return (
      <div className="bg-white min-h-screen relative">
        {!isAdminRoute && <Header />}
      {!isAdminRoute && <WhatsappFloat message="Hola! ¿Cómo podemos ayudarte?" />}        
      <ProductsProvider>
      <Routes>
        {/* Rutas Públicas Envuelves con Layout */}
        <Route path="/" element={<Layout />}>
          <Route path="nosotros" element={<Nosotros />} />
          <Route path="inicio" element={<Inicio />} />
          <Route path="contacto" element={<ContactPage />} />
          <Route path="nuestros-clientes" element={<NuestrosClientes />} />
          <Route path="productos" element={<Productos />} />
          
          <Route path="/productos/servicios" element={<ServiciosPage />} />
          <Route path="productos/:categoria/:subcategoria/:model" element={<ProductoDetalle />} />
          <Route path="productos/:categoria" element={<Categoria />} />
          <Route path="/productos/:category/:subcategory" element={<FilteredProductsPage />} />          
          <Route path="productos/todos" element={<AllProducts />} />
           {/* Ruta por defecto */}
           <Route index element={<Navigate to="/inicio" replace />} />
          {/* Ruta 404 Opcional */}
          <Route path="*" element={<NotFound />} />
        </Route>

          {/* Rutas de Administración */}
        {/* Ruta de Login Fuera de ProtectedRoute */}
        <Route path="/admin/login" element={<Login />} />
{/* Rutas de Administración Protegidas */}
<Route path="/admin" element={<ProtectedRoute />}>
  <Route path="products" element={<ProductsPage />} />
  {/* Agrega más rutas de administración aquí */}
  <Route path="*" element={<Navigate to="/admin/login" replace />} />
</Route>
</Routes>
      </ProductsProvider>
    </div>
  );
} 

export default App;
