import { Link } from 'react-router-dom'
import { FiFacebook, FiInstagram} from "react-icons/fi";
import { PiTiktokLogo } from "react-icons/pi";
import Logo from '../assets/images/logo-blanco-cohuin.png'
const routes = [
  { name: 'Inicio', href: '/' },
  { name: 'Nosotros', href: '/nosotros' },
  { name: 'Productos', href: '/productos' },
  { name: 'Clientes', href: '/nuestros-clientes' },
  { name: 'Contacto', href: '/contacto' },
]

const products = [
  { name: 'EPP', href: '/productos/Equipos%20de%20Protección%20Personal%20(EPP)' },
  { name: 'Señalización', href: '/productos/Señalización' },
  { name: 'Ferretería', href: '/productos/Ferretería' },
  { name: 'Servicios', href: '/productos/Servicios' },
]

export function Footer() {
  return (
    <footer className="bg-blue-900 text-white">
      <div className="container mx-auto px-4 py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {/* Logo and company info */}
          <div className="space-y-4">
            <img
              src={Logo}
              alt="Corporación Cohuin Logo"
              width={200}
              height={60}
              className="p-2 rounded-md"
            />
            <p className="text-sm text-blue-200">
              Soluciones integrales en equipos de protección personal y señalización
            </p>
          </div>

          {/* Main routes */}
          <div>
            <h3 className="text-lg font-semibold mb-4 text-blue-300">Enlaces Rápidos</h3>
            <ul className="space-y-2">
              {routes.map((route) => (
                <li key={route.name}>
                  <Link to={route.href} className="text-sm hover:text-blue-400 transition-colors">
                    {route.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Product categories */}
          <div>
            <h3 className="text-lg font-semibold mb-4 text-blue-300">Nuestros Productos</h3>
            <ul className="space-y-2">
              {products.map((product) => (
                <li key={product.name}>
                  <Link to={product.href} className="text-sm hover:text-blue-400 transition-colors">
                    {product.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Contact info and social media */}
          <div>
            <h3 className="text-lg font-semibold mb-4 text-blue-300">Contáctanos</h3>
            <p className="text-sm mb-2">ventas@cohuin.com</p>
            <p className="text-sm mb-2">+51 944 888 761</p>
            <p className="text-sm mb-2">+51 974 657 332</p>
            <p className="text-sm mb-4">+51 932 723 864</p>
            <div className="flex space-x-4">
              <a href="#" className="text-blue-300 hover:text-white transition-colors">
                <FiFacebook size={20} />
              </a>
              <a href="#" className="text-blue-300 hover:text-white transition-colors">
                <FiInstagram size={20} />
              </a>
              <a href="#" className="text-blue-300 hover:text-white transition-colors">
                <PiTiktokLogo size={20} />
              </a>
            </div>
          </div>
        </div>

        {/* Bottom bar */}
        <div className="mt-12 pt-8 border-t border-blue-800 text-center text-sm text-blue-400">
          <p>&copy; {new Date().getFullYear()} Corporación Cohuin S.A.C. Todos los derechos reservados.</p>
        </div>
      </div>
    </footer>
  )
}

