'use client'

import React, { useCallback, useEffect, useState, useRef } from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import { EmblaOptionsType } from 'embla-carousel'
import { ChevronLeft, ChevronRight } from 'lucide-react'
import { cn } from '../libs/utils'

type BrandCarouselProps = {
  brands: { name: string; logo: string }[]
  className?: string
}

const BrandCarousel: React.FC<BrandCarouselProps> = ({ brands, className }) => {
  const options: EmblaOptionsType = {
    loop: true,
    align: 'start',
    skipSnaps: false,
    dragFree: true,
    containScroll: 'trimSnaps',
  } 
  const [emblaRef, emblaApi] = useEmblaCarousel(options)

  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false)

  const scrollPrev = useCallback(() => emblaApi && emblaApi.scrollPrev(), [emblaApi])
  const scrollNext = useCallback(() => emblaApi && emblaApi.scrollNext(), [emblaApi])

  const onSelect = useCallback(() => {
    if (!emblaApi) return
    setPrevBtnEnabled(emblaApi.canScrollPrev())
    setNextBtnEnabled(emblaApi.canScrollNext())
  }, [emblaApi])

  useEffect(() => {
    if (!emblaApi) return
    onSelect()
    emblaApi.on('select', onSelect)
    emblaApi.on('reInit', onSelect)
  }, [emblaApi, onSelect])

  // Implementación del auto-slide
  useEffect(() => {
    if (!emblaApi) return

    const autoSlide = setInterval(() => {
      emblaApi.scrollNext()
    }, 4000) 

    return () => {
      clearInterval(autoSlide)
    }
  }, [emblaApi])

  return (
    <div className={cn('relative', className)}>
      <div className="overflow-hidden" ref={emblaRef}>
        <div className="flex">
          {brands.map((brand, index) => (
            <div 
              key={index} 
              className="flex-[0_0_50%] sm:flex-[0_0_33.333%] md:flex-[0_0_25%] min-w-0 py-4 px-4"
            >
              <div className="bg-white rounded-[30px] shadow-md p-6 flex items-center justify-center h-40 sm:h-40 md:h-48">
                <img
                  src={brand.logo}
                  alt={brand.name}
                  className="max-w-full max-h-full object-contain"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <button
        className="absolute left-0 top-1/2 -translate-y-1/2 bg-white/80 text-blue-600 rounded-full p-2 shadow-md hover:bg-white transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
        onClick={scrollPrev}
        disabled={!prevBtnEnabled}
        aria-label="Anterior"
      >
        <ChevronLeft className="w-6 h-6" />
      </button>
      <button
        className="absolute right-0 top-1/2 -translate-y-1/2 bg-white/80 text-blue-600 rounded-full p-2 shadow-md hover:bg-white transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
        onClick={scrollNext}
        disabled={!nextBtnEnabled}
        aria-label='Siguiente'
      >
        <ChevronRight className="w-6 h-6" />
      </button>
    </div>
  )
}

export default BrandCarousel